import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ParceriaDto } from 'src/app/shared/dto/ParceriaDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { AdicionarTokenComponent } from './adicionar-token/adicionar-token.component';
import { GerarTokenComponent } from './gerar-token/gerar-token.component';
import { LocalizacaoComponent } from './localizacao/localizacao.component';
import { ParceriaService } from './parceria.service';

@Component({
  selector: 'app-parceria',
  templateUrl: './parceria.component.html',
  styleUrls: ['./parceria.component.scss']
})
export class ParceriaComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  fornecedores: ParceriaDto[] = [];
  distribuidores: ParceriaDto[] = [];

  role: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private service: ParceriaService,
    private modalService: NgbModal,
    private utilService: UtilService
  ) {
    const decodedToken = this.tokenStorageService.getDecodedToken();
    this.role = decodedToken.role;
  }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, 'fornecedor')
      .subscribe((response: PaginaDto) => {
        this.fornecedores = response.content;
        this.collectionSize = response.totalElements;
      });
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, 'distribuidor')
      .subscribe((response: PaginaDto) => {
        this.distribuidores = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  gerarToken() {
    const modalOpcoes = this.modalService.open(GerarTokenComponent, { size: 'lg' });
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
    });
  }

  adicionarToken() {
    const modalOpcoes = this.modalService.open(AdicionarTokenComponent, { size: 'md' });
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
      this.carregarDados();
    });
  }

  adicionarLocalizacao(id: number, coordenada: number[]) {
    const modalOpcoes = this.modalService.open(LocalizacaoComponent, { size: 'xl' });
    modalOpcoes.componentInstance.id = id;
    modalOpcoes.componentInstance.coordenada = coordenada;
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
      this.carregarDados();
    });
  }

  excluir(id: number) {
    this.utilService.mensagemConfirmacao(`Deseja realmente excluir?`, UtilService.MENSAGEM_ALERTA)
      .then((result) => {
        if (result.isConfirmed) {
          this.service.excluir(id).subscribe(res => {
            this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
            this.carregarDados();
          }, error => {
            this.utilService.mensagem(
              this.utilService.traduzErro(error),
              UtilService.MENSAGEM_ERRO
            );
          });
        }
      });
  }

}
