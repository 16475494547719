<div class="modal-header">
    <h4 class="modal-title">Adicionar</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">
    <form [formGroup]="form" class="row">
        <div class="form-group col-12">
            <label class="form-label" for="token">Token</label>
            <input type="text" formControlName="token" id="token" class="form-control" />
            <div *ngIf="submitted && f.token.invalid" class="alert alert-danger"> Token é obrigatório! </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-raised-button class="btn-azul mr-2" type="button" (click)="validar()">Adicionar</button>
    <button mat-raised-button color="warn" type="button" (click)="modal.close()">Cancelar</button>
</div>