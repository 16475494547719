import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AtributoOpcaoDto } from 'src/app/shared/dto/ProdutoTipoAtributoDto';

@Component({
  selector: 'app-modal-opcoes',
  templateUrl: './modal-opcoes.component.html',
  styleUrls: ['./modal-opcoes.component.scss']
})
export class ModalOpcoesComponent implements OnInit {

  @Input() public opcoes: AtributoOpcaoDto[] = [];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  adicionar() {
    this.opcoes.push({
      nome: ''
    });
  }

  excluir(opcao) {
    this.opcoes.forEach((item, index) => {
      if (item === opcao) this.opcoes.splice(index, 1);
    });
  }

  subir(index) {
    if (index > 0) {
      let el = this.opcoes[index];
      this.opcoes[index] = this.opcoes[index - 1];
      this.opcoes[index - 1] = el;
    }
  }

  descer(index) {
    if (index !== -1 && index < this.opcoes.length - 1) {
      let el = this.opcoes[index];
      this.opcoes[index] = this.opcoes[index + 1];
      this.opcoes[index + 1] = el;
    }
  }

  salvar() {
    this.modal.close();
    this.passEntry.emit(this.opcoes);
  }

}
