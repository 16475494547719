import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrdemServicoListaComponent } from './lista/lista.component';
import { OrdemServicoFormularioComponent } from './formulario/formulario.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    OrdemServicoListaComponent,
    OrdemServicoFormularioComponent,
  ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, BrowserModule, NgbModule, NgxMaskModule.forRoot()
  ],
  exports: [
    OrdemServicoListaComponent,
    OrdemServicoFormularioComponent
  ]
})
export class OrdemServicoModule { }
