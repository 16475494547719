<div *ngIf="role === 'administrador'" class="d-flex justify-content-between m-2">
    <h4>Distrubuídores</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="gerarToken()"><i class="bi bi-key"></i> Gerar
        Token</button>
</div>
<div *ngIf="role === 'administrador'" class="formulario-box">
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Nome</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Início</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of distribuidores">
                    <td scope="row" class="text-left">{{ item.nomeEmpresa }}</td>
                    <td scope="row">{{ item.endereco }}</td>
                    <td scope="row">{{ item.telefone }}</td>
                    <td scope="row">{{ item.inicio | date: 'dd/MM/yyyy' }}</td>
                    <td scope="row">
                        <button mat-icon-button class="btn-warn mr-2" type="button" (click)="excluir(item.id)"><i
                                class="bi bi-trash"></i></button>
                        <button mat-icon-button class="btn-azul" type="button"
                            (click)="adicionarLocalizacao(item.id, item.coordenada)"><i class="bi bi-map"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div *ngIf="role === 'administrador'" class="d-flex justify-content-between m-2 mt-4">
    <h4>Fornecedores</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="adicionarToken()"><i
            class="bi bi-unlock-fill"></i> Adicionar
        Token</button>
</div>
<div *ngIf="role === 'administrador'" class="formulario-box">
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Nome</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Início</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of fornecedores">
                    <td scope="row" class="text-left">{{ item.nomeEmpresa }}</td>
                    <td scope="row">{{ item.endereco }}</td>
                    <td scope="row">{{ item.telefone }}</td>
                    <td scope="row">{{ item.inicio | date: 'dd/MM/yyyy' }}</td>
                    <td scope="row">
                        <button mat-raised-button class="btn-warn mr-2" type="button" (click)="excluir(item.id)"><i
                                class="bi bi-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>