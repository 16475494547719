import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { OrdemServicoDto } from 'src/app/shared/dto/OrdemServicoDto';
import { OrdemServicoItemDto } from 'src/app/shared/dto/OrdemServicoItemDto';
import { OrdemServicoItensDto } from 'src/app/shared/dto/OrdemServicoItensDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ClienteService } from '../../cliente/cliente.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { UsuarioService } from '../../usuario/usuario.service';
import { OrdemServicoService } from '../ordemservico.service';
import * as moment from 'moment';

@Component({
  selector: 'app-produto-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class OrdemServicoFormularioComponent implements OnInit {

  id;
  loading = false;
  submitted = false;
  produtoTipos: ProdutoTipoDto[] = [];
  responsaveis: UsuarioDto[] = [];
  locais: LocalDto[] = [{
    id: 0,
    nome: 'Cliente'
  }];
  clientes: ClienteDto[] = [];
  mostrarClientes: boolean;
  itens: OrdemServicoItemDto[] = [];

  form = new FormGroup({
    responsavel: new FormControl('', [Validators.required]),
    local: new FormControl('0', [Validators.required]),
    cliente: new FormControl(''),
    dataHora: new FormControl('', [Validators.required]),
    encerramento: new FormControl(''),
  });

  constructor(
    private service: OrdemServicoService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private produtoTipoService: ProdutoTipoService,
    private produtoLocalService: ProdutoLocalService,
    private clienteService: ClienteService
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
  }

  ngOnInit(): void {
    this.listaResponsavel();
    this.listaProdutoTipo();
    this.listaLocal();
    this.listaCliente();

    if (this.id !== 'novo') {
      this.service.obterPorId(this.id).subscribe((res: OrdemServicoItensDto) => {
        this.id = res.ordemServico.id;
        this.mostrarClientes = res.ordemServico.local == undefined || res.ordemServico.local == null;
        this.form.setValue({
          responsavel: res.ordemServico.responsavel ? res.ordemServico.responsavel.id : null,
          local: res.ordemServico.local ? res.ordemServico.local.id : '0',
          cliente: res.ordemServico.cliente ? res.ordemServico.cliente.id : null,
          dataHora: res.ordemServico.dataHora !== null
            ? moment.parseZone(res.ordemServico.dataHora).local().format('DD/MM/YYYY HH:mm:ss')
            : '',
          encerramento: res.ordemServico.encerramento !== null
            ? moment.parseZone(res.ordemServico.encerramento).local().format('DD/MM/YYYY HH:mm:ss')
            : '',
        })
        this.itens = res.itens;
      }, error => {
        console.log(error);
      })
    } else {
      this.mostrarClientes = true;
    }
  }

  get f() {
    return this.form.controls;
  }

  adicionarItem() {
    const atributo: OrdemServicoItemDto = {
      quantidade: 0,
      ordem: this.itens.length
    }
    this.itens.push(atributo);
  }

  excluirItem(item) {
    this.itens.forEach((itemAux, index) => {
      if (itemAux === item) this.itens.splice(index, 1);
    });
  }

  subirItem(index) {
    if (index > 0) {
      let el = this.itens[index];
      this.itens[index] = this.itens[index - 1];
      this.itens[index - 1] = el;

      this.itens[index].ordem = index;
      el.ordem = index - 1;
    }
  }

  descerItem(index) {
    if (index !== -1 && index < this.itens.length - 1) {
      let el = this.itens[index];
      this.itens[index] = this.itens[index + 1];
      this.itens[index + 1] = el;

      this.itens[index].ordem = index;
      el.ordem = index + 1;
    }
  }

  listaResponsavel() {
    this.usuarioService.lista().subscribe((res: UsuarioDto[]) => {
      this.responsaveis = res;
    }, error => {
      console.log(error);
    })
  }

  listaProdutoTipo() {
    this.produtoTipoService.lista().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
    }, error => {
      console.log(error);
    })
  }

  listaCliente() {
    this.clienteService.lista().subscribe((res: ClienteDto[]) => {
      this.clientes = res;
    }, error => {
      console.log(error);
    })
  }

  alterarLocal(id) {
    this.mostrarClientes = id === 0 || id === '0';
  }

  cadastrar() {
    console.log(this.form.invalid)
    console.log(this.form)

    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }

    const id = (this.id !== 'novo') ? this.id : null;
    const responsavelId: number = Number(this.form.get('responsavel').value);
    const localId: number = Number(this.form.get('local').value);
    const clienteId: number = Number(this.form.get('cliente').value);
    const dataHora = this.form.get('dataHora').value;
    const encerramento = this.form.get('encerramento').value;

    if (this.mostrarClientes === true && clienteId === 0) {
      this.form.controls['cliente'].setErrors({ 'incorrect': true });
      return;
    }

    const ordemServico: OrdemServicoDto = {
      id,
      responsavel: (responsavelId === 0) ? null : {
        id: responsavelId
      },
      local: (localId === 0) ? null : {
        id: localId
      },
      cliente: (this.mostrarClientes === false || clienteId === 0) ? null : {
        id: clienteId
      },
      dataHora: dataHora.length > 0 ? this.toDate(dataHora) : null,
      encerramento: encerramento.length > 0 ? this.toDate(encerramento) : null
    };
    const ordemServicoItensDto: OrdemServicoItensDto = {
      ordemServico,
      itens: this.itens
    };

    console.log(ordemServicoItensDto)

    this.loading = true;
    this.service.cadastrar(ordemServicoItensDto).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
        this.router.navigateByUrl('/ordem-servico');
      },
      (error) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(error),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }

  toDate(str) {
    const [dateComponents, timeComponents] = str.split(' ');
    const [day, month, year] = dateComponents.split('/');
    const [hours, minutes, seconds] = timeComponents.split(':');
    return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
  }

  cancelar() {
    this.router.navigateByUrl('/ordem-servico');
  }
}
