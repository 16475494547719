<div class="d-flex justify-content-between m-2">
    <h4>Ordens de Serviço</h4>
    <button class="btn btn-primary" type="button" (click)="editar('novo')"><i class="bi bi-plus-circle"></i> Novo</button>
</div>
<div class="formulario-box">
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col">Id</th>
                    <th scope="col" class="text-left">Responsável</th>
                    <th scope="col" class="text-left">Local</th>
                    <th scope="col" class="text-left">Cliente</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row">{{ item.id }}</td>
                    <td scope="row" class="text-left">{{ item.responsavel.nome }}</td>
                    <td scope="row" class="text-left">{{ item.local ? item.local.nome : 'Cliente' }}</td>
                    <td scope="row" class="text-left">{{ item.cliente ? item.cliente.nome : '' }}</td>
                    <td scope="row" class="acoes">
                        <button class="btn btn-primary mr-2" (click)="editar(item.id)"><i class="bi bi-pencil-square"></i></button>
                        <button class="btn btn-primary" (click)="excluir(item.id)"><i class="bi bi-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize" [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>