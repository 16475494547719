import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParceriaTokenDto } from 'src/app/shared/dto/ParceriaTokenDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ParceriaTokenService } from '../parceria-token.service';
import Map from 'ol/Map';
import View from 'ol/View';
import Layer from 'ol/layer/Layer';
import BingMaps from 'ol/source/BingMaps';
import { Overlay } from 'ol';
import { Style, Text, Fill, Icon } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4';
import { get as getProjection, getTransform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { Control, defaults as defaultControls } from 'ol/control.js';
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { ParceriaService } from '../parceria.service';

@Component({
  selector: 'app-localizacao',
  templateUrl: './localizacao.component.html',
  styleUrls: ['./localizacao.component.scss']
})
export class LocalizacaoComponent implements OnInit {

  mapa;
  source;
  botaoLocalizacaoAtivo = false;
  salvarLocalControle;
  selecionarLocalControle;
  desenho;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() id: number;
  @Input() coordenada: number[];

  constructor(
    public modal: NgbActiveModal,
    private service: ParceriaService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.initMapa(this.coordenada);
  }

  initMapa(coordinates?: number[]) {
    const that = this;

    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');

    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const newProjCode = 'EPSG:4326';
    proj4.defs(newProjCode, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
    register(proj4);
    const newProj = getProjection(newProjCode);

    let view = new View({
      projection: newProj,
      center: [-51.95756207384352, -14.061509571510168],
      zoomFactor: 2,
      zoom: 4,
      rotation: 0
    });

    const raster = new TileLayer({
      source: new OSM(),
    });


    if (coordinates && coordinates[0] !== 0 && coordinates[1] !== 0) {
      view = new View({
        projection: newProj,
        center: coordinates,
        zoomFactor: 2,
        zoom: 4,
        rotation: 0
      });

      const geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
          'type': 'name',
          'properties': {
            'name': 'EPSG:4326',
          },
        },
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': coordinates,
            },
          },
        ],
      };
      this.source = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject)
      });
    } else {
      this.source = new VectorSource();
    }

    const style = new Style({
      image: new Icon({
        anchor: [0.5, 42],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: './../../assets/map-pin.png',
      }),
    });


    const vector = new VectorLayer({
      source: this.source,
      style
    });

    this.mapa = new Map({
      layers: [raster, vector],
      target: 'mapa',
      view,
      overlays: [overlay],
    });


    this.adicionarBotaoSelecionarLocal();
  }

  adicionarBotaoSelecionarLocal() {
    const that = this;

    let button = document.createElement('button');

    const selecionarLocal = function (e) {
      that.selecionarLocal()
    };

    button.addEventListener('click', selecionarLocal, false);

    let icone = document.createElement('i');
    icone.className = 'bi bi-geo-fill';
    button.appendChild(icone);

    let div = document.createElement('div');
    div.className = 'ol-unselectable ol-control selecionar-local';
    div.style.cssText = 'top: 80px; left: .5em;';
    div.appendChild(button);

    this.selecionarLocalControle = new Control({
      element: div,
    });
    this.selecionarLocalControle.set('nome', 'desenho');
    this.mapa.addControl(this.selecionarLocalControle);
  }

  selecionarLocal() {
    const that = this;
    this.desenho = new Draw({
      source: this.source,
      type: 'Point',
    });
    this.desenho.on('drawend', function (evt) {
      var features = that.source.getFeatures();
      if (features.length >= 1) {
        var feature = features[0];
        that.source.removeFeature(feature);
      }
      that.adicionarBotaoSalvarLocal();

    }, this);
    this.mapa.addInteraction(this.desenho);
  }

  adicionarBotaoSalvarLocal() {
    if (!this.botaoLocalizacaoAtivo) {
      this.botaoLocalizacaoAtivo = true;
      const that = this;
      let button = document.createElement('button');

      const salvarLocal = function (e) {
        that.salvarLocal()
      };

      button.addEventListener('click', salvarLocal, false);

      let icone = document.createElement('i');
      icone.className = 'bi bi-check';
      button.appendChild(icone);

      let div = document.createElement('div');
      div.className = 'ol-unselectable ol-control selecionar-local';
      div.style.cssText = 'top: 115px; left: .5em;';
      div.appendChild(button);

      this.salvarLocalControle = new Control({
        element: div,
      });
      this.salvarLocalControle.set('nome', 'desenho');
      this.mapa.addControl(this.salvarLocalControle);
    }
  }

  salvarLocal() {
    let features = this.source.getFeatures();

    let geogJONSformat = new GeoJSON();
    let featuresGeojson = geogJONSformat.writeFeaturesObject(features);
    let geojsonFeatureArray = featuresGeojson.features;

    if (features.length >= 1) {
      this.mapa.removeControl(this.salvarLocalControle);
      this.mapa.removeInteraction(this.desenho);
      this.botaoLocalizacaoAtivo = false;
      this.service.salvarCoordenada({
        id: this.id,
        geomJson: JSON.stringify(geojsonFeatureArray[0].geometry)
      }).subscribe(
        (res: any) => {
          this.utilService.mensagem('Local salvo!', UtilService.MENSAGEM_SUCESSO)
          this.fechar();
        },
        (error) => {
          this.utilService.mensagem(
            this.utilService.traduzErro(error),
            UtilService.MENSAGEM_ERRO
          );
        }
      );
    } else {
      this.utilService.mensagem(
        'Necessário selecioanr um local no mapa!',
        UtilService.MENSAGEM_ALERTA
      );
    }
  }

  fechar() {
    this.passEntry.emit('retorno');
    this.modal.close();
  }

}
