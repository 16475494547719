import { ChangeDetectorRef, Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { UsuarioService } from '../paginas/usuario/usuario.service';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.scss'],
})
export class CabecalhoComponent implements OnDestroy {
  @Output() sidenav: EventEmitter<any> = new EventEmitter();
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  usuarioLogado: string;
  empresaLogada: EmpresaDto;
  codEmpresa: string;
  empresas: EmpresaDto[] = [];
  token;

  constructor(
    private tokenStorageService: TokenStorageService,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private utilService: UtilService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
  ) {
    const usuario = this.tokenStorageService.getUserToken();
    this.usuarioLogado = usuario.nome;
    this.token = usuario.token;

    const decodedToken = this.tokenStorageService.getDecodedToken();
    this.codEmpresa = decodedToken.empresa;

    this.populaEmpresas();

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleMenu() {
    this.sidenav.emit();
  }

  async populaEmpresas() {
    this.empresas = await this.usuarioService.listaEmpresas().toPromise();
    this.empresaLogada = this.empresas.filter(e => e.codigo === this.codEmpresa)[0];
  }

  alterarEmpresa(codigoEmpresa) {
    if (this.codEmpresa !== codigoEmpresa) {
      console.log(this.token)
      this.authService.useLoginToken(this.token, codigoEmpresa).subscribe(
        (res: any) => {
          this.tokenStorageService.saveToken(res.token);
          this.tokenStorageService.saveUserToken(res);
          window.location.reload();
        },
        (error) => {
          console.log(error);
          this.utilService.mensagem(
            this.utilService.traduzErro(error),
            UtilService.MENSAGEM_ERRO
          );
        }
      );
    }
  }

  sair(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['login']);
  }

}
