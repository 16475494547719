import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertaDto } from 'src/app/shared/dto/AlertaDto';
import { ProdutoAtributosDto } from 'src/app/shared/dto/ProdutoAtributosDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/alerta`;

@Injectable({
  providedIn: 'root'
})
export class AlertaService {


  constructor(
    private http: HttpClient
  ) { }

  cadastrar(produtoAtributosDto: AlertaDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, produtoAtributosDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number): Observable<Object> {
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/${id}`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }

  relatorioDetalhesAlerta(local: string, tipo: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${API}/relatorio-detalhes-alerta?local=${local}&tipo=${tipo}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  obterQuantidadeProdutosAlerta(tipo: string) {
    return this.http.get(`${API}/quantidade-produtos-alerta?tipo=${tipo}`);
  }
}
