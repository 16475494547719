<div class="d-flex justify-content-between m-2">
    <h4>Movimentação</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i>
        Voltar</button>
</div>
<div class="formulario-box" *ngIf="selecionarLocal">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Local</mat-label>
                <mat-select formControlName="local" (selectionChange)="alterarLocal($event.value)" required>
                    <mat-option *ngFor="let local of locais" [value]="local.id"> {{local.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.local.hasError('required')"> Altere o local </mat-error>
                <mat-hint>{{f.local.value?.id}}</mat-hint>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarDistribuidor" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Distribuidor</mat-label>
                <mat-select formControlName="distribuidor">
                    <mat-option *ngFor="let distribuidor of distribuidores" [value]="distribuidor.codigo">
                        {{distribuidor.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarFornecedor" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Fornecedor</mat-label>
                <mat-select formControlName="fornecedor">
                    <mat-option *ngFor="let fornecedor of fornecedores" [value]="fornecedor.codigo"> {{fornecedor.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarClientes" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Cliente</mat-label>
                <input type="text" placeholder="Cliente" aria-label="Number" matInput formControlName="cliente"
                    [matAutocomplete]="auto" (keyup)="filterCliente($event.target.value)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                    <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente">
                        {{cliente.nome}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="f.cliente.hasError('required')"> Cliente é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarEntregador" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Entregador</mat-label>
                <mat-select formControlName="entregador">
                    <mat-option *ngFor="let entregador of entregadores" [value]="entregador.id"> {{entregador.nome}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.entregador.hasError('required')"> Entregador é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="row">
        <ng-container *ngFor="let atributo of atributos; let i = index">
            <div class="form-group col-xs-12 col-sm-4">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{atributo.atributo.nome}}</mat-label>
                    <mat-select *ngIf="atributo.atributo.tipo === 'opcoes'" [(ngModel)]="atributo.valor">
                        <mat-option *ngFor="let opcao of atributo.atributo.opcoesObj" [value]="opcao.nome">
                            {{opcao.nome}} </mat-option>
                    </mat-select>
                    <input *ngIf="atributo.atributo.tipo !== 'opcoes'" type="text" matInput [(ngModel)]="atributo.valor"
                        name="valor">
                </mat-form-field>

            </div>
        </ng-container>
    </div>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="salvarLocal()">Próximo</button>
    </div>
</div>

<div class="formulario-box" *ngIf="selecionarProduto">
    <div class="row">
        <div class="col-xs-12 col-sm-4 form-group">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Código</mat-label>
                <input #codigoRef type="text" matInput [(ngModel)]="codigo" name="codigo"
                    (ngModelChange)="codigo = $event.toUpperCase()" (keyup)="parouDeDigitar()">
            </mat-form-field>
        </div>
        <div class="btn-mob">
            <button mat-icon-button class="btn-azul" type="button" (click)="buscarProdutoPorCodigo()"><i
                    class="bi bi-plus-circle"></i></button>
        </div>
    </div>
    <mat-list>
        <mat-list-item class="list-item" *ngFor="let produtoAtributo of produtos.reverse();">
            <div class="d-flex justify-content-between">
                <div>
                    <div class="item-header">
                        <div>{{produtoAtributo.produto.codigo}} - {{produtoAtributo.produto.tipoId ?
                            obterProdutoTipoPorId(produtoAtributo.produto.tipoId).nome :
                            ''}}</div>
                    </div>
                    <div class="item-bottom">
                        <div class="atributo" *ngFor="let atributos of produtoAtributo.atributos">
                            <b>{{atributos.atributo.nome}}:</b>
                            {{atributos.valor}}
                        </div>
                    </div>
                </div>
                <div>
                    <button mat-icon-button class="btn-azul" type="button"
                        (click)="excluirDoLote(produtoAtributo.produto.id)"><i class="bi bi-trash"></i></button>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button"
            (click)="voltarSelecionarProduto()">Voltar</button>
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="salvarProdutosLote()">Próximo</button>
    </div>
</div>

<div class="formulario-box" *ngIf="confirmarMovimentacao">
    <div class="row" *ngFor="let form of produtosForm">
        <div class="col-12">
            <mat-list>
                <mat-list-item class="d-flex">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Tipo</mat-label>
                        <mat-select aria-label="tipo" id="tipo" [(ngModel)]="form.tipo" placeholder="Selecione um tipo"
                            disabled>
                            <mat-option *ngFor="let tipo of produtoTipos" [value]="tipo.id">{{tipo.nome}}
                                ({{form.codigos.length}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-icon-button class="btn-azul" (click)="editarForm(form)">
                        <mat-icon *ngIf="form.editar ? true : false">remove_circle_outline</mat-icon>
                        <mat-icon *ngIf="form.editar ? false : true">add_circle_outline</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
        <ng-container *ngIf="form.editar ? true : false">
            <div class="col-md-12" *ngFor="let atributo of form.atributos; let i = index">
                <mat-list-item *ngIf="atributo.tipo !== 'opcoes'" class="d-flex">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>{{atributo.nome}}</mat-label>
                        <input matInput name="atributoValor" [(ngModel)]="atributo.valor"
                            disabled="{{atributo.editar ? false : true}}">
                    </mat-form-field>
                    <mat-button class="cursor-pointer" mat-icon-button item-right (click)="editarAtributo(atributo)">
                        <mat-icon *ngIf="atributo.editar ? true : false">flash_on_outline</mat-icon>
                        <mat-icon *ngIf="atributo.editar ? false : true">flash_off_outline</mat-icon>
                    </mat-button>
                </mat-list-item>
                <mat-list *ngIf="atributo.tipo === 'opcoes'">
                    <mat-list-item class="d-flex">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>{{atributo.nome}}</mat-label>
                            <mat-select [(ngModel)]="atributo.valor" placeholder="Selecione uma opção"
                                disabled="{{atributo.editar ? false : true}}">
                                <mat-option *ngFor="let opcao of atributo.opcoesObj" [value]="opcao.nome">{{opcao.nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-button class="cursor-pointer" mat-icon-button (click)="editarAtributo(atributo)">
                            <mat-icon *ngIf="atributo.editar ? true : false">flash_on_outline</mat-icon>
                            <mat-icon *ngIf="atributo.editar ? false : true">flash_off_outline</mat-icon>
                        </mat-button>
                    </mat-list-item>
                </mat-list>
            </div>
        </ng-container>
    </div>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button"
            (click)="voltarConfirmarMovimentacao()">Voltar</button>
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="salvarMovimentacao()">Salvar</button>
    </div>
</div>