import { Component, HostListener, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { EmpresaService } from '../paginas/configuracao-empresa/empresa.service';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent implements OnInit {

  public getScreenWidth: any;
  public getScreenHeight: any;

  mostrarMenuResponsivo: boolean;
  navbarAberto = false;

  role: string;
  imagemPreview: any;

  constructor(
    private sanitizer: DomSanitizer,
    private tokenStorageService: TokenStorageService,
    private empresaService: EmpresaService
  ) {
    const decodedToken = this.tokenStorageService.getDecodedToken();
    this.role = decodedToken.role;
    this.populaImagemLogo(decodedToken.empresa)

  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.menuResponsivo();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.menuResponsivo();
  }

  menuResponsivo() {
    if (this.getScreenWidth > 799) {
      this.mostrarMenuResponsivo = false;
    } else {
      this.mostrarMenuResponsivo = true
    }
  }

  abrirFecharMenu() {
    this.navbarAberto = !this.navbarAberto;
  }


  populaImagemLogo(codigo) {
    this.empresaService.obterPorCodigo(codigo).subscribe((res: EmpresaDto) => {
      if (res.imagem) {
        const imageUrl = 'data:image/png;base64,' + res.imagem;
        this.imagemPreview = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }
    }, error => {
      console.log(error);
    })
  }
}
