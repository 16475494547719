import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';


@Injectable({
  providedIn: 'root'
})
export class VisaoGeralService {

  constructor(
    private http: HttpClient
  ) { }

  obterTotalizadorProdutoLocalizacao(pagina: number, tamanho: number): Observable<Object> {
    return this.http.get(`${UtilService.API}/produto/obter-totalizador-protudo-localizacao?page=${pagina}&size=${tamanho}`);
  }

  obterTotalizadorLocalizacao(): Observable<Object> {
    return this.http.get(`${UtilService.API}/produto/obter-totalizador-localizacao`);
  }

  obterGraficoProdutosLocais() {
    return this.http.get(`${UtilService.API}/grafico/obter-grafico-produtos-locais`);
  }

  listaLocalProduto() {
    return this.http.get(`${UtilService.API}/local-produto/feature-collection`);
  }

}
