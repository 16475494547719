import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { EmpresaService } from '../empresa.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-usuario-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class ConfiguracaoEmpresaFormularioComponent implements OnInit {

  codigo;
  loading = false;
  submitted = false;
  imagemSelecionada: File;
  imagemPreview: any;
  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl(''),
    telefone: new FormControl(''),
    endereco: new FormControl(''),
    imagem: new FormControl('')
  });

  role: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private service: EmpresaService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {
    const decodedToken = this.tokenStorageService.getDecodedToken();
    this.codigo = decodedToken.empresa;
    this.role = decodedToken.role;
  }

  ngOnInit(): void {
    this.service.obterPorCodigo(this.codigo).subscribe((res: EmpresaDto) => {
      if (res.imagem) {
        const imageUrl = 'data:image/png;base64,' + res.imagem;
        this.imagemPreview = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }
      this.form.setValue({
        nome: res.nome,
        cnpj: res.cnpj,
        telefone: res.telefone,
        endereco: res.endereco,
        imagem: ''
      });
    }, error => {
      console.log(error);
    })
  }

  get f() {
    return this.form.controls;
  }

  onFileSelected(event): void {
    this.imagemSelecionada = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      this.imagemPreview = reader.result as string;
    };
    reader.readAsDataURL(this.imagemSelecionada);
  }

  cadastrar() {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('nome', this.form.get('nome').value);
    formData.append('cnpj', this.form.get('cnpj').value);
    formData.append('endereco', this.form.get('endereco').value);
    formData.append('telefone', this.form.get('telefone').value);
    formData.append('imagem', this.imagemSelecionada);

    this.loading = true;
    this.service.salvar(formData).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
      },
      (error) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(error),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }

  cancelar() {
    this.router.navigateByUrl('/usuario');
  }
}
