import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { ClienteService } from '../../cliente/cliente.service';
import { UsuarioService } from '../../usuario/usuario.service';
import { ParceriaService } from '../../parceria/parceria.service';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { AtributoProdutoDto } from 'src/app/shared/dto/AtributoProdutoDto';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs/internal/Observable';
import { ProdutoService } from '../../produto/produto.service';


@Component({
  selector: 'relatorio-totalizador-produto-formulario',
  templateUrl: './relatorio-totalizador-produto.component.html',
  styleUrls: ['./relatorio-totalizador-produto.component.scss']
})
export class RelatorioTotalizadorProdutoFormularioComponent implements OnInit {

  loading = false;
  submitted = false;
  produtoTipos: ProdutoTipoDto[] = [];
  atributos: AtributoProdutoDto[] = [];
  locais: LocalDto[] = [{
    id: -1,
    nome: 'Entregador'
  },
  {
    id: 0,
    nome: 'Cliente'
  },
  {
    id: -3,
    nome: 'Distribuidor'
  }];
  clientes: ClienteDto[] = [];
  clientesFiltrados: ClienteDto[] = [];
  entregadores: UsuarioDto[] = [];
  distribuidores: EmpresaDto[] = [];
  fornecedores: EmpresaDto[] = [];
  mostrarClientes: boolean;
  mostrarEntregador: boolean;
  mostrarDistribuidor: boolean;
  mostrarFornecedor: boolean;
  filteredOptions: Observable<string[]>;

  @ViewChild('clientesField') clientesField: MatSelect;

  form = new FormGroup({
    tipo: new FormControl(''),
    local: new FormControl(''),
    entregador: new FormControl(''),
    cliente: new FormControl(''),
    distribuidor: new FormControl(''),
  });

  constructor(
    private service: ProdutoService,
    private utilService: UtilService,
    private produtoTipoService: ProdutoTipoService,
    private produtoLocalService: ProdutoLocalService,
    private clienteService: ClienteService,
    private usuarioService: UsuarioService,
    private parceriaService: ParceriaService,
  ) { }


  ngOnInit(): void {
    this.listaLocal();
    this.listaCliente();
    this.listaEntregadores();
    this.listaDistribuidores();
    this.listaProdutoTipo();
  }

  get f() {
    return this.form.controls;
  }

  filterCliente(val) {
    let filter = this.clientes.filter((cliente) => cliente.nome.toLowerCase().indexOf(val.toLowerCase()) !== -1);
    this.clientesFiltrados = [...filter];
  }

  selectedclient(event) {
    console.log(event.option.value);
  }

  getOptionText(option) {
    return option.nome;
  }

  async listaProdutoTipo() {
    const produtosTipos: any = await this.produtoTipoService.lista().toPromise();
    this.produtoTipos = produtosTipos;
    const produtosTiposFornecedor = await this.produtoTipoService.listaFornecedor().toPromise();
    this.produtoTipos = this.produtoTipos.concat(produtosTiposFornecedor);
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
    }, error => {
      console.log(error);
    })
  }

  listaCliente() {
    this.clienteService.lista().subscribe((res: ClienteDto[]) => {
      this.clientes = res;
      this.clientesFiltrados = res;
    }, error => {
      console.log(error);
    })
  }

  listaEntregadores() {
    this.usuarioService.listaEntregadores().subscribe((res: UsuarioDto[]) => {
      this.entregadores = res;
    }, error => {
      console.log(error);
    })
  }

  listaDistribuidores() {
    this.parceriaService.obterDistribuidores().subscribe((res: EmpresaDto[]) => {
      this.distribuidores = res;
    }, error => {
      console.log(error);
    })
  }

  alterarLocal(id) {
    this.mostrarClientes = id === 0 || id === '0';
    this.mostrarEntregador = id === -1 || id === '-1';
    this.mostrarDistribuidor = id === -3 || id === '-3';
  }

  imprimir(): void {
    const tipoId: number = Number(this.form.get('tipo').value);
    const localId: number = Number(this.form.get('local').value);
    const clienteId: number = Number(this.form.get('cliente').value.id ? this.form.get('cliente').value.id : '');
    const entregadorId: number = Number(this.form.get('entregador').value);
    const distribuidorId: number = Number(this.form.get('distribuidor').value);
    let filtroLocal = '';

    if (localId !== 0 && localId !== -1 && localId !== -2 && localId !== -3 && localId !== -4) {
      filtroLocal = `local:${localId}`;
    } else if (this.mostrarClientes === true) {
      filtroLocal = `cliente:${clienteId}`;
    } else if (this.mostrarEntregador === true) {
      filtroLocal = `entregador:${entregadorId}`;
    } else if (this.mostrarDistribuidor === true) {
      filtroLocal = `distribuidor:${distribuidorId}`;
    }

    this.service
      .relatorioTotalizadorPorLocalizacao(filtroLocal, tipoId)
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/pdf',
        });
        const filename = 'relatorio-totalizador-produto.pdf';
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveBlob(blob, filename);
        } else {
          var elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      });
  }

  gerarExcel(): void {
    const tipoId: number = Number(this.form.get('tipo').value);
    const localId: number = Number(this.form.get('local').value);
    const clienteId: number = Number(this.form.get('cliente').value.id ? this.form.get('cliente').value.id : '');
    const entregadorId: number = Number(this.form.get('entregador').value);
    const distribuidorId: number = Number(this.form.get('distribuidor').value);
    let filtroLocal = '';

    if (localId !== 0 && localId !== -1 && localId !== -2 && localId !== -3 && localId !== -4) {
      filtroLocal = `local:${localId}`;
    } else if (this.mostrarClientes === true) {
      filtroLocal = `cliente:${clienteId}`;
    } else if (this.mostrarEntregador === true) {
      filtroLocal = `entregador:${entregadorId}`;
    } else if (this.mostrarDistribuidor === true) {
      filtroLocal = `distribuidor:${distribuidorId}`;
    }

    this.service
      .relatorioTotalizadorPorLocalizacaoExcel(filtroLocal, tipoId)
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = 'relatorio-totalizador-produto.xlsx';
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveBlob(blob, filename);
        } else {
          var elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      });
  }
}