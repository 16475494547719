import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/empresa`;

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(
    private http: HttpClient
  ) { }

  salvar(empresa: FormData): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, empresa, options);
  }

  obterPorCodigo(codigo: string) {
    return this.http.get(`${API}/${codigo}`);
  }

}
