import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProdutoAtributosDto } from 'src/app/shared/dto/ProdutoAtributosDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/produto`;

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {


  constructor(
    private http: HttpClient
  ) { }

  cadastrar(produtoAtributosDto: ProdutoAtributosDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, produtoAtributosDto, options);
  }

  salvarLote(produtosAtributosDto: ProdutoAtributosDto[]): any {
    return this.http.post(`${API}/salvarLote`, produtosAtributosDto);
  }

  paginaFiltrar(pagina: number, tamanho: number, search: string): Observable<Object> {
    const filtro = search ? `&search=${search}` : `&search=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtro}`);
  }

  paginaFornecedorFiltrar(pagina: number, tamanho: number, search: string): Observable<Object> {
    const filtro = search ? `&search=${search}` : `&search=`;
    return this.http.get(`${API}/pagina-fornecedor-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtro}`);
  }

  listaFiltrar(codigo: string, tipo: string): Observable<Object> {
    return this.http.get(`${API}/lista-filtrar?codigo=${codigo}&tipo=${tipo}`);
  }

  listaDetalhesProdutoLocal(pagina: number, tamanho: number, local: string): Observable<Object> {
    return this.http.get(`${API}/lista-detalhes-produto-local?page=${pagina}&size=${tamanho}&local=${local}`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/obter-produto-atributos/${id}`);
  }

  obterPorCodigo(codigo: string) {
    return this.http.get(`${API}/obter-produto-atributos-por-codigo/${codigo}`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }

  relatorioTotalizadorPorLocalizacao(local: string, tipo: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${API}/relatorio-totalizador-por-localizacao?local=${local}&tipo=${tipo}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  relatorioTotalizadorPorLocalizacaoExcel(local: string, tipo: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${API}/relatorio-totalizador-por-localizacao-excel?local=${local}&tipo=${tipo}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  relatorioCodigoProdutosExcel(tipo: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${API}/relatorio-codigo-produtos-excel?tipo=${tipo}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
