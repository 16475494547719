import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { UsuarioService } from '../usuario.service';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { UtilService } from 'src/app/shared/service/util.service';

@Component({
  selector: 'app-usuario-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class UsuarioListaComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: UsuarioDto[] = [];

  role: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private service: UsuarioService,
    private router: Router,
    private utilService: UtilService
  ) {
    const decodedToken = this.tokenStorageService.getDecodedToken();
    this.role = decodedToken.role;
  }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, undefined, undefined)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  editar(id) {
    this.router.navigateByUrl('/usuario/' + id);
  }

  excluir(id: number) {
    this.service.excluir(id).subscribe(res => {
      this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    })
  }

}
