import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoDescricaoDto } from 'src/app/shared/dto/ProdutoDescricaoDto';
import { ProdutoDto } from 'src/app/shared/dto/ProdutoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { ProdutoService } from '../../produto/produto.service';
import { EtiquetaService } from '../etiqueta.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-etiqueta-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class EtiquetaListaComponent implements OnInit {

  codigo = '';
  tipoId = '';

  loading = false;
  submitted = false;
  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ProdutoDescricaoDto[] = [];
  produtoTipos: ProdutoTipoDto[] = [];

  form = new FormGroup({
    letras: new FormControl('', [Validators.required]),
    inicio: new FormControl('', [Validators.required]),
    fim: new FormControl('', [Validators.required]),
    casas: new FormControl('', [Validators.required]),
    excecoes: new FormControl('')
  });

  constructor(
    private service: EtiquetaService,
    private utilService: UtilService,
    private produtoTipoService: ProdutoTipoService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  get f() {
    return this.form.controls;
  }

  listaProdutoTipo() {
    this.produtoTipoService.lista().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  imprimir() {
    window.print();
  }

  editar(id) {
    this.router.navigateByUrl('/produto/' + id);
  }

  gerar(): void {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }
    this.loading = true;

    const letras: string = this.form.get('letras').value;
    const inicio: number = this.form.get('inicio').value;
    const fim: number = this.form.get('fim').value;
    const casas: number = this.form.get('casas').value;
    const excecoes: string = this.form.get('excecoes').value;

    const codigos: string[] = [];
    const excecoesArray: number[] = excecoes.split(',').map(Number);
    for (let i = Number(inicio); i <= Number(fim); i++) {
      let codigoNumero: string = i.toString().padStart(casas, '0');
      if (excecoesArray.indexOf(i) === -1) {
        let codigo: string = letras + codigoNumero;
        codigos.push(codigo);
      }
    }
    this.service
      .gerar(codigos)
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/pdf',
        });
        const filename = 'etiquetas.pdf';
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveBlob(blob, filename);
        } else {
          var elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

}
