import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DetalhesProdutoLocalDto } from 'src/app/shared/dto/DetalhesProdutoLocalDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoDescricaoDto } from 'src/app/shared/dto/ProdutoDescricaoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoService } from '../../produto/produto.service';

@Component({
  selector: 'app-detalhes-produtos-local',
  templateUrl: './detalhes-produtos-local.component.html',
  styleUrls: ['./detalhes-produtos-local.component.scss']
})
export class DetalhesProdutoLocalComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() id: number;
  @Input() local: string;

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: DetalhesProdutoLocalDto[] = [];

  constructor(
    public modal: NgbActiveModal,
    private produtoService: ProdutoService,
    private utilService: UtilService
  ) { }


  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.produtoService
      .listaDetalhesProdutoLocal(this.page - 1, this.pageSize, `${this.local}:${this.id}`)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  visualizarCodigos(item: DetalhesProdutoLocalDto) {
    item.mostrarCodigos = !item.mostrarCodigos;
  }


}
