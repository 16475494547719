<div class="modal-header">
    <h4 class="modal-title">Localização</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">
    <div id="mapa">
    </div>
</div>
<div class="modal-footer">
    <button mat-raised-button color="warn" type="button" (click)="fechar()">Fechar</button>
</div>