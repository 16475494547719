<div class="loading-overlay" *ngIf="loading">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50"></mat-progress-spinner>
</div>
<div class="d-flex justify-content-between m-2">
    <h4>Etiquetas</h4>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-2">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Letras Iniciais</mat-label>
                <input type="text" matInput formControlName="letras">
                <mat-error *ngIf="f.letras.hasError('required')"> Letras é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-2">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Casas numéricas</mat-label>
                <input type="text" matInput formControlName="casas">
                <mat-error *ngIf="f.casas.hasError('required')"> Casas é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-2">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Início</mat-label>
                <input type="text" matInput formControlName="inicio">
                <mat-error *ngIf="f.inicio.hasError('required')"> Início é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-2">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Fim</mat-label>
                <input type="text" matInput formControlName="fim">
                <mat-error *ngIf="f.fim.hasError('required')"> Fim é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-4">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Números exceções (separado por vírgula)</mat-label>
                <input type="text" matInput formControlName="excecoes">
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul" type="button" (click)="gerar()"><i class="bi bi-printer"></i>
            Gerar</button>
    </div>
</div>