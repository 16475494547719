import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RelatorioTotalizadorProdutoFormularioComponent } from './relatorio-totalizador-produto/relatorio-totalizador-produto.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RelatorioCodigoProdutosComponent } from './relatorio-codigo-produtos/relatorio-codigo-produtos.component';
import { RelatorioTotalizadorEntregadorComponent } from './relatorio-totalizador-entregador/relatorio-totalizador-entregador.component';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    RelatorioTotalizadorProdutoFormularioComponent,
    RelatorioCodigoProdutosComponent,
    RelatorioTotalizadorEntregadorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgbModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatSelectFilterModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    RelatorioTotalizadorProdutoFormularioComponent,
    RelatorioCodigoProdutosComponent,
    RelatorioTotalizadorEntregadorComponent
  ],
})
export class RelatorioModule { }
