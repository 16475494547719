<div class="d-flex justify-content-between m-2">
    <h4>Movimentações</h4>
    <div>
        <button mat-raised-button class="btn-azul" type="button" (click)="editar('novo')"><i
                class="bi bi-plus-circle"></i>
            Movimentar</button>
    </div>
</div>

<div class="formulario-box">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-xs-12 col-sm-3 form-group">
                <mat-form-field class="w-100">
                    <mat-label>Usuário</mat-label>
                    <mat-select formControlName="usuario">
                        <mat-option *ngFor="let responsavel of responsaveis" [value]="responsavel.id">
                            {{responsavel.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2 form-group">
                <mat-form-field class="w-100">
                    <mat-label>Data inicial</mat-label>
                    <input matInput formControlName="dataInicial" placeholder="00/00/0000 00:00"
                        mask="00/00/0000 00:00">
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-2 form-group">
                <mat-form-field class="w-100">
                    <mat-label>Data final</mat-label>
                    <input matInput formControlName="dataFinal" placeholder="00/00/0000 00:00" mask="00/00/0000 00:00">
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-5 form-group">
                <label>Tipo de movimentação</label>
                <section class="example-section">
                    <mat-checkbox class="example-margin" (change)="ajustaFiltroEntradaSaida('entrada')"
                        formControlName="entrada">Entradas</mat-checkbox>
                    <mat-checkbox class="example-margin" (change)="ajustaFiltroEntradaSaida('saida')"
                        formControlName="saida">Saídas</mat-checkbox>
                </section>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-xs-12 col-sm-3">
                <mat-form-field class="w-100">
                    <mat-label>Local</mat-label>
                    <mat-select formControlName="local" (selectionChange)="alterarLocal($event.value)">
                        <mat-option *ngFor="let local of locais" [value]="local.id"> {{local.nome}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="mostrarClientes" class="form-group col-xs-12 col-sm-4">
                <mat-form-field class="w-100">
                    <mat-label>Cliente</mat-label>
                    <input type="text" placeholder="Cliente" aria-label="Number" matInput formControlName="cliente"
                        [matAutocomplete]="auto" (keyup)="filterCliente($event.target.value)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                        <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente">
                            {{cliente.nome}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div *ngIf="mostrarEntregador" class="form-group col-xs-12 col-sm-4">
                <mat-form-field class="w-100">
                    <mat-label>Entregador</mat-label>
                    <mat-select formControlName="entregador">
                        <mat-option *ngFor="let entregador of responsaveis" [value]="entregador.id"> {{entregador.nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="btn-mob">
                <button mat-raised-button class="btn-azul mr-2" type="button" (click)="carregarDados()"><i
                        class="bi bi-search"></i></button>
                <button mat-raised-button class="btn-azul mr-2" type="button" (click)="pdf()"><mat-icon>print</mat-icon>
                </button>
                <!-- <button mat-raised-button class="btn-azul" type="button" (click)="pdf()"><mat-icon>print</mat-icon>
                    Excel</button> -->
            </div>
        </div>
    </form>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr class="text-center">
                    <th scope="col">Data</th>
                    <th scope="col">Usuário</th>
                    <th scope="col" class="text-left">Movimentação</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row">{{ item.nomeUsuario }}</td>
                    <td scope="row" class="text-left">{{ item.descricao }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize"
        [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>