<div class="modal-header">
    <h4 class="modal-title">Detalhes da localização</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Tipo</th>
                    <th scope="col" class="text-left">Detalhes</th>
                    <th scope="col" class="text-center">Quantidade</th>
                    <th scope="col" class="text-center">Códigos</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <ng-container *ngFor="let item of lista">
                    <tr>
                        <td scope="row" class="text-left">{{ item.tipo }}</td>
                        <td scope="row" class="text-left">{{ item.detalhes }}</td>
                        <td scope="row" class="text-center">{{ item.quantidade }}</td>
                        <td scope="row" class="text-center">
                            <i *ngIf="!item.mostrarCodigos || item.mostrarCodigos === false"
                                (click)="visualizarCodigos(item)"
                                class="cursor-pointer cor-azul bi bi-plus-circle pointer"></i>
                            <i *ngIf="item.mostrarCodigos && item.mostrarCodigos === true"
                                (click)="visualizarCodigos(item)"
                                class="cursor-pointer cor-azul bi bi-dash-circle pointer"></i>
                        </td>
                    </tr>
                    <tr *ngIf="item.mostrarCodigos && item.mostrarCodigos === true" style="background-color: #eee;">
                        <td colspan="4">{{item.codigos}}</td>
                    </tr>
                </ng-container>

            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize"
        [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>
<div class="modal-footer">
    <button mat-raised-button color="warn" type="button" (click)="modal.close()">Cancelar</button>
</div>