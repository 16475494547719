<div class="modal-header">
    <h4 class="modal-title">Gerar</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Token</th>
                    <th scope="col">Geração</th>
                    <th scope="col">Válido</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row" class="text-left">{{ item.token }}</td>
                    <td scope="row">{{ item.geracao | date: 'dd/MM/yyyy' }}</td>
                    <td scope="row">{{ item.valido ? 'Sim' : 'Não' }}</td>
                    <td scope="row">
                        <button mat-icon-button class="btn-warn" type="button" (click)="excluir(item.id)"><i
                class="bi bi-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button mat-raised-button class="btn-azul mr-2" type="button" (click)="gerar()">Gerar</button>
    <button mat-raised-button color="warn" type="button" (click)="modal.close()">Cancelar</button>
</div>