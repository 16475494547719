import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { MovimentacaoSolicitacaoService } from 'src/app/shared/service/movimentacao-solicitacao.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { UsuarioService } from '../../usuario/usuario.service';

@Component({
  selector: 'app-confirmar-solicitacao',
  templateUrl: './confirmar-solicitacao.component.html',
  styleUrls: ['./confirmar-solicitacao.component.scss']
})
export class ConfirmarSolicitacaoComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() solicitacao: any;

  locais: LocalDto[] = [];
  entregadores: UsuarioDto[] = [];

  mostrarEntregador: boolean;
  loading = false;
  submitted = false;
  form = new FormGroup({
    local: new FormControl('', [Validators.required]),
    entregador: new FormControl(''),
  });

  constructor(
    public modal: NgbActiveModal,
    private movimentacaoSolicitacaoService: MovimentacaoSolicitacaoService,
    private produtoLocalService: ProdutoLocalService,
    private usuarioService: UsuarioService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.form.controls['entregador'].disable();
    this.listaLocal();
    this.listaEntregadores();
    if (this.solicitacao.entregadorId && this.solicitacao.entregadorId !== null) {
      setTimeout(() => {
        this.form.setValue({
          local: -1,
          entregador: this.solicitacao.entregadorId,
        })
        this.alterarLocal(-1);
      }, 500);
    }
  }

  confirmarSolicitacao() {
    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }

    const localId: number = Number(this.form.get('local').value);
    const dto = {
      id: this.solicitacao.id,
      confirmar: true,
      localId
    }

    this.movimentacaoSolicitacaoService.confirmarSolicitacao(dto).subscribe(res => {
      this.utilService.mensagem(`Solicitação aceita!`, UtilService.MENSAGEM_SUCESSO);
      this.passEntry.emit('retorno');
      this.modal.close();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    });
  }

  alterarLocal(id) {
    this.mostrarEntregador = id === -1 || id === '-1';
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
      if (this.solicitacao.entregadorId && this.solicitacao.entregadorId != null) {
        this.locais = this.locais.concat([
          {
            id: -1,
            nome: 'Entregador'
          }
        ]);
      }
    }, error => {
      console.log(error);
    })
  }

  listaEntregadores() {
    this.usuarioService.listaEntregadores().subscribe((res: UsuarioDto[]) => {
      this.entregadores = res;
    }, error => {
      console.log(error);
    })
  }

  get f() {
    return this.form.controls;
  }

}
