<div class="modal-header">
    <h4 class="modal-title">Confirmar solicitação</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">
    <form [formGroup]="form" class="row">
        <div class="form-group col-12">
            <label class="form-label" for="local">Local</label>
            <select class="form-control" id="local" formControlName="local" (change)="alterarLocal($event.target.value)">
        <option *ngFor="let local of locais" value="{{local.id}}"> {{local.nome}} </option>
      </select>
            <div *ngIf="submitted && f.local.invalid" class="alert alert-danger"> Altere o local! </div>
        </div>
        <div *ngIf="mostrarEntregador" class="form-group col-12">
            <label class="form-label" for="entregador">Entregador</label>
            <select class="form-control" id="entregador" formControlName="entregador">
        <option *ngFor="let entregador of entregadores" value="{{entregador.id}}"> {{entregador.nome}} </option>
      </select>
            <div *ngIf="submitted && f.entregador.invalid" class="alert alert-danger"> Entregador é obrigatório! </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-raised-button class="btn-azul mr-2" type="button" (click)="confirmarSolicitacao()">Confirmar</button>
    <button mat-raised-button color="warn" type="button" (click)="modal.close()">Cancelar</button>
</div>