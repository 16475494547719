import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoAtributosDto } from 'src/app/shared/dto/ProdutoAtributosDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoService } from '../../produto/produto.service';
import { ClienteService } from '../cliente.service';
import Map from 'ol/Map';
import View from 'ol/View';
import Layer from 'ol/layer/Layer';
import BingMaps from 'ol/source/BingMaps';
import { Overlay } from 'ol';
import { Style, Text, Fill, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4';
import { get as getProjection, getTransform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { Control, defaults as defaultControls } from 'ol/control.js';
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { LocalProdutoService } from 'src/app/shared/service/local-produto.service';

@Component({
  selector: 'app-cliente-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class ClienteFormularioComponent implements OnInit {

  id;
  loading = false;
  submitted = false;

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: any[] = [];
  layers: Layer[] = [];

  geomJson: string;

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    cnpj: new FormControl(''),
    endereco: new FormControl('', [Validators.required]),
    informacao: new FormControl(''),
  });

  mapa;
  source;
  botaoLocalizacaoAtivo = false;
  salvarLocalControle;
  selecionarLocalControle;
  desenho;

  enderecoPesquisa: string = '';

  constructor(
    private service: ClienteService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private produtoService: ProdutoService,
    private localProdutoService: LocalProdutoService,
    private router: Router,
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
  }

  ngOnInit(): void {
    if (this.id !== 'novo') {
      this.service.obterPorId(this.id).subscribe((res: ClienteDto) => {
        this.id = res.id;
        this.form.setValue({
          nome: res.nome,
          cnpj: res.cnpj,
          endereco: res.endereco,
          informacao: res.informacao,
        });
        this.initMapa(res.coordenada);
      }, error => {
        console.log(error);
      })
      this.carregarDados();
    } else {
      this.initMapa();
    }
  }

  get f() {
    return this.form.controls;
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.produtoService
      .listaDetalhesProdutoLocal(this.page - 1, this.pageSize, `cliente:${this.id}`)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  cadastrar() {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }

    const cliente: ClienteDto = this.form.getRawValue();
    cliente.id = (this.id !== 'novo') ? this.id : null;

    let features = this.source.getFeatures();
    let geogJONSformat = new GeoJSON();
    let featuresGeojson = geogJONSformat.writeFeaturesObject(features);
    let geojsonFeatureArray = featuresGeojson.features;

    if (features.length >= 1) {
      this.mapa.removeControl(this.salvarLocalControle);
      this.mapa.removeInteraction(this.desenho);
      this.botaoLocalizacaoAtivo = false;
      cliente.geomJson = JSON.stringify(geojsonFeatureArray[0].geometry);
    }

    this.loading = true;
    this.service.cadastrar(cliente).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
        this.router.navigateByUrl('/cliente');
      },
      (error) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(error),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }

  pesquisarEndereco() {
    this.localProdutoService
      .pesquisarEndereco(this.enderecoPesquisa)
      .subscribe(
        (xy) => {
          const coordenadas = [xy[0], xy[1]];
          const local = new Feature({
            geometry: new Point(coordenadas),
          });

          var features = this.source.getFeatures();
          if (features.length >= 1) {
            var feature = features[0];
            this.source.removeFeature(feature);
          }

          this.source.addFeatures([local]);
          this.mapa.getView().setCenter(coordenadas);
          this.mapa.getView().setZoom(13);
          this.adicionarBotaoSalvarLocal();
        },
        (error) => {
          this.utilService.mensagem(
            this.utilService.traduzErro(error),
            UtilService.MENSAGEM_ERRO
          );
        }
      );
  }

  initMapa(coordinates?: number[]) {
    const that = this;

    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');

    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const newProjCode = 'EPSG:4326';
    proj4.defs(newProjCode, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
    register(proj4);
    const newProj = getProjection(newProjCode);

    let view = new View({
      projection: newProj,
      center: [-51.95756207384352, -14.061509571510168],
      zoomFactor: 2,
      zoom: 4,
      rotation: 0
    });

    const raster = new TileLayer({
      source: new OSM(),
    });

    if (coordinates && coordinates[0] !== 0 && coordinates[1] !== 0) {
      view = new View({
        projection: newProj,
        center: coordinates,
        zoomFactor: 2,
        zoom: 4,
        rotation: 0
      });

      const geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
          'type': 'name',
          'properties': {
            'name': 'EPSG:4326',
          },
        },
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': coordinates,
            },
          },
        ],
      };
      this.source = new VectorSource({
        features: new GeoJSON().readFeatures(geojsonObject)
      });
    } else {
      this.source = new VectorSource();
    }

    const style = new Style({
      image: new Icon({
        anchor: [0.5, 42],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: './../../assets/map-pin.png',
      }),
    });


    const vector = new VectorLayer({
      source: this.source,
      style
    });

    this.mapa = new Map({
      layers: [raster, vector],
      target: 'mapa',
      view,
      overlays: [overlay],
    });


    this.adicionarBotaoSelecionarLocal();
  }

  adicionarBotaoSelecionarLocal() {
    const that = this;

    let button = document.createElement('button');

    const selecionarLocal = function (e) {
      that.selecionarLocal()
    };

    button.addEventListener('click', selecionarLocal, false);

    let icone = document.createElement('i');
    icone.className = 'bi bi-geo-fill';
    button.appendChild(icone);

    let div = document.createElement('div');
    div.className = 'ol-unselectable ol-control selecionar-local';
    div.style.cssText = 'top: 80px; left: .5em;';
    div.appendChild(button);

    this.selecionarLocalControle = new Control({
      element: div,
    });
    this.selecionarLocalControle.set('nome', 'desenho');
    this.mapa.addControl(this.selecionarLocalControle);
  }

  selecionarLocal() {
    const that = this;
    this.desenho = new Draw({
      source: this.source,
      type: 'Point',
    });
    this.desenho.on('drawend', function (evt) {
      var features = that.source.getFeatures();
      if (features.length >= 1) {
        var feature = features[0];
        that.source.removeFeature(feature);
      }
      that.adicionarBotaoSalvarLocal();

    }, this);
    this.mapa.addInteraction(this.desenho);
  }

  adicionarBotaoSalvarLocal() {
    if (!this.botaoLocalizacaoAtivo) {
      this.botaoLocalizacaoAtivo = true;
      const that = this;
      let button = document.createElement('button');

      const salvarLocal = function (e) {
        that.salvarLocal()
      };

      button.addEventListener('click', salvarLocal, false);

      let icone = document.createElement('i');
      icone.className = 'bi bi-check';
      button.appendChild(icone);

      let div = document.createElement('div');
      div.className = 'ol-unselectable ol-control selecionar-local';
      div.style.cssText = 'top: 115px; left: .5em;';
      div.appendChild(button);

      this.salvarLocalControle = new Control({
        element: div,
      });
      this.salvarLocalControle.set('nome', 'desenho');
      this.mapa.addControl(this.salvarLocalControle);
    }
  }

  salvarLocal() {
    if (this.id !== 'novo') {
      let features = this.source.getFeatures();

      let geogJONSformat = new GeoJSON();
      let featuresGeojson = geogJONSformat.writeFeaturesObject(features);
      let geojsonFeatureArray = featuresGeojson.features;

      if (features.length >= 1) {
        this.mapa.removeControl(this.salvarLocalControle);
        this.mapa.removeInteraction(this.desenho);
        this.botaoLocalizacaoAtivo = false;
        this.service.salvarCoordenada({
          id: this.id,
          geomJson: JSON.stringify(geojsonFeatureArray[0].geometry)
        }).subscribe(
          (res: any) => {
            this.utilService.mensagem('Local salvo!', UtilService.MENSAGEM_SUCESSO)
          },
          (error) => {
            this.loading = false;
            this.utilService.mensagem(
              this.utilService.traduzErro(error),
              UtilService.MENSAGEM_ERRO
            );
          }
        );
      } else {
        this.utilService.mensagem(
          'Necessário selecioanr um local no mapa!',
          UtilService.MENSAGEM_ALERTA
        );
      }
    }
  }

  cancelar() {
    this.router.navigateByUrl('/cliente');
  }

}
