import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/cliente`;

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(
    private http: HttpClient
  ) { }

  cadastrar(clienteDto: ClienteDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, clienteDto, options);
  }

  salvarCoordenada(geometriaDto: any): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar-coordenada`, geometriaDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number, search: string): Observable<Object> {
    const filtro = search ? `&search=${search}` : `&search=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtro}`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/${id}`);
  }

  lista() {
    return this.http.get(`${API}/lista`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }
}
