import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { TokenStorageService } from '../auth/token-storage.service';
import { AutenticarDto } from '../shared/dto/AutenticarDto';
import { UtilService } from '../shared/service/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  submitted = false;
  isLoggedIn = false;

  form = new FormGroup({
    email: new FormControl('', [Validators.required]),
    senha: new FormControl('', [Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.router.navigate(['/']);
    }
    this.isLoggedIn = false;
  }

  get f() {
    return this.form.controls;
  }

  login() {
    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }
    const autenticarDto: AutenticarDto = this.form.getRawValue();

    this.loading = true;
    this.authService.login(autenticarDto).subscribe(
      (res: any) => {
        this.loading = false;

        const decodedToken = this.tokenStorageService.decodeToken(res.token);
        if (decodedToken.role === 'entregador') {
          this.utilService.mensagem('Usuário não possui permissão!',
            UtilService.MENSAGEM_ERRO
          );
          return;
        }

        this.tokenStorageService.saveToken(res.token);
        this.tokenStorageService.saveUserToken(res);
        this.router.navigateByUrl('/');
      },
      (error) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(error),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }
}
