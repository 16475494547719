import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoDescricaoDto } from 'src/app/shared/dto/ProdutoDescricaoDto';
import { ProdutoDto } from 'src/app/shared/dto/ProdutoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { ProdutoService } from '../produto.service';
import { RelatorioModalComponent } from '../relatorio-modal/relatorio-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-produto-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ProdutoListaComponent implements OnInit {

  codigo: string;
  tipoId = '';

  codigoFornecedor: string;
  tipoIdFornecedor = '';

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ProdutoDescricaoDto[] = [];
  produtoTipos: ProdutoTipoDto[] = [];
  active = 1;

  pageSizeFornecedor: number = 5;
  collectionSizeFornecedor: number;
  pageFornecedor: number = 0;
  previousPageFornecedor: number;
  listaFornecedor: ProdutoDescricaoDto[] = [];
  produtoTiposFornecedor: ProdutoTipoDto[] = [];

  constructor(
    private service: ProdutoService,
    private utilService: UtilService,
    private produtoTipoService: ProdutoTipoService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.listaProdutoTipo();
    this.carregarDados();
    this.listaProdutoTipoFornecedor();
    this.carregarDadosFornecedor();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  listaProdutoTipo() {
    this.produtoTipoService.lista().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  carregarDados() {
    let filtro: string = '';
    if (this.codigo && this.codigo !== '') {
      filtro = `codigo:${this.codigo}`;
    }
    if (this.tipoId && this.tipoId !== '0' && this.tipoId !== '') {
      filtro = filtro.length > 0 ? filtro + ',' : '';
      filtro = filtro + `tipo_id:${this.tipoId}`;
    }
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, filtro)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  carregarPaginaFornecedor(page: number) {
    if (page !== this.previousPageFornecedor) {
      this.previousPageFornecedor = page;
      this.carregarDadosFornecedor();
    }
  }

  listaProdutoTipoFornecedor() {
    this.produtoTipoService.listaFornecedor().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTiposFornecedor = res;
    }, error => {
      console.log(error);
    })
  }

  carregarDadosFornecedor() {
    let filtro: string = '';
    if (this.codigoFornecedor && this.codigoFornecedor !== '') {
      filtro = `codigo:${this.codigoFornecedor}`;
    }
    if (this.tipoIdFornecedor && this.tipoIdFornecedor !== '0' && this.tipoIdFornecedor !== '') {
      filtro = filtro.length > 0 ? filtro + ',' : '';
      filtro = filtro + `tipo_id:${this.tipoIdFornecedor}`;
    }
    this.service
      .paginaFornecedorFiltrar(this.pageFornecedor - 1, this.pageSizeFornecedor, filtro)
      .subscribe((response: PaginaDto) => {
        this.listaFornecedor = response.content;
        this.collectionSizeFornecedor = response.totalElements;
      });
  }

  editar(id) {
    this.router.navigateByUrl('/produto/' + id);
  }

  excluir(id: number) {
    this.service.excluir(id).subscribe(res => {
      this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    })
  }

  obterAlerta(dias, diasRestantes) {
    if (dias && diasRestantes) {
      const colors = ['#dc3545', '#ffc800']
      let color;
      if (diasRestantes < 0) {
        color = colors[0];
      } else if (diasRestantes < 10) {
        color = colors[1];
      }
      return color;
    }

  };

  relatorioModal() {
    const modalOpcoes = this.modalService.open(RelatorioModalComponent, { size: 'md' });
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
    });
  }

}
