import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from 'src/app/shared/service/util.service';
import { ConfiguracaoDto } from '../dto/ConfiguracaoDto';

const API = `${UtilService.API}/configuracao`;

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService {

  constructor(
    private http: HttpClient
  ) { }

  salvar(localDto: ConfiguracaoDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, localDto, options);
  }

  obter() {
    return this.http.get(`${API}/obter`);
  }

}
