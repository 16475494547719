<section class="vh-100 login">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <div class="card shadow-2-strong">
                    <div class="card-body p-5 text-center">
                        <img src="../../assets/logo-collie2.png">
                        <form *ngIf="!isLoggedIn" [formGroup]="form" (ngSubmit)="login()">
                            <div class="form-group text-left">
                                <mat-form-field class="w-100">
                                    <mat-label>Email</mat-label>
                                    <input type="email" matInput formControlName="email">
                                    <mat-error *ngIf="f.email.hasError('required')"> Email é <strong>obrigatório</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group text-left">
                                <mat-form-field class="w-100">
                                    <mat-label>Senha</mat-label>
                                    <input type="password" matInput formControlName="senha">
                                    <mat-error *ngIf="f.senha.hasError('required')"> Senha é <strong>obrigatório</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-check d-flex justify-content-start mb-4">
                                <input class="form-check-input" type="checkbox" value="" id="form1Example3" />
                                <label class="form-check-label" for="form1Example3"> Lembrar senha </label>
                            </div>
                            <button mat-raised-button class="btn-azul w-100" type="submit">Entrar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>