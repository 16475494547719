import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MovimentacaoHistoricoDto } from 'src/app/shared/dto/MovimentacaoHistoricoDto';
import { MovimentacaoSolicitacaoDto } from 'src/app/shared/dto/MovimentacaoSolicitacaoDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { MovimentacaoSolicitacaoService } from 'src/app/shared/service/movimentacao-solicitacao.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ConfirmarSolicitacaoComponent } from './confimar-solicitacao/confirmar-solicitacao.component';
import { DetalhesProdutoLocalComponent } from './detalhes-produtos-local/detalhes-produtos-local.component';
import { DetalhesSolicitacaoComponent } from './detalhes-solicitacao/detalhes-solicitacao.component';
import { VisaoGeralService } from './visao-geral.service';
import Map from 'ol/Map';
import View from 'ol/View';
import Layer from 'ol/layer/Layer';
import BingMaps from 'ol/source/BingMaps';
import { Overlay } from 'ol';
import CircleStyle from 'ol/style/Circle';
import { Style, Text, Fill, Icon } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4';
import { get as getProjection, getTransform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { FullScreen, Control, defaults as defaultControls } from 'ol/control.js';
import { ClienteService } from '../cliente/cliente.service';
import { ConfiguracaoService } from 'src/app/shared/service/configuracao.service';
import { ConfiguracaoDto } from 'src/app/shared/dto/ConfiguracaoDto';
import { RelatorioAlertaModalComponent } from './relatorio-alerta-modal/relatorio-alerta-modal.component';
import { AlertaService } from '../alerta/alerta.service';

@Component({
  selector: 'app-visao-geral',
  templateUrl: './visao-geral.component.html',
  styleUrls: ['./visao-geral.component.scss']
})
export class VisaoGeralComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: MovimentacaoHistoricoDto[] = [];

  pageSizeTotalizadorLocalizacao: number = 5;
  collectionSizeTotalizadorLocalizacao: number;
  pageTotalizadorLocalizacao: number = 0;
  previousPageTotalizadorLocalizacao: number;
  listaTotalizadorLocalizacao: any[] = [];

  listaSolicitacoes: MovimentacaoSolicitacaoDto[] = [];
  listaSolicitacoesFeitas: MovimentacaoSolicitacaoDto[] = [];

  graficoProdutosLocais = [];

  view;

  gradient: boolean = true;
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  mapa;
  source;
  botaoLocalizacaoAtivo = false;
  salvarLocalControle;
  selecionarLocalControle;
  coordenada: number[] = [];

  configuracao: ConfiguracaoDto;

  qtdEmdia = 0;
  qtdAvencer = 0;
  qtdOciosos = 0;

  constructor(
    private service: VisaoGeralService,
    private movimentacaoHistoricoService: MovimentacaoHistoricoService,
    private movimentacaoSolicitacaoService: MovimentacaoSolicitacaoService,
    private configuracaoService: ConfiguracaoService,
    private modalService: NgbModal,
    private utilService: UtilService,
    private alertaService: AlertaService
  ) {
    this.view = [innerWidth / 1.5, 400];
  }

  ngOnInit(): void {
    this.carregarDados();
    this.carregarConfiguracao();
    this.obterQuantidadeProdutosAlerta('emdia');
    this.obterQuantidadeProdutosAlerta('avencer');
    this.obterQuantidadeProdutosAlerta('ociosos');
  }

  obterQuantidadeProdutosAlerta(tipo: string) {
    this.alertaService.obterQuantidadeProdutosAlerta(tipo).subscribe((res: number) => {
      console.log(res)
      if (tipo == 'emdia') {
        this.qtdEmdia = res;
      } else if (tipo == 'avencer') {
        this.qtdAvencer = res;
      } else if (tipo == 'ociosos') {
        this.qtdOciosos = res;
      }
    }, (error) => {
      this.initMapa();
    })
  }

  carregarConfiguracao() {
    this.configuracaoService.obter().subscribe(res => {
      console.log(res)
      this.configuracao = res;
      this.initMapa(this.configuracao.extent);
    }, (error) => {
      this.initMapa();
    })
  }

  onResize(event) {
    console.log(event.target.innerWidth)
    this.view = [event.target.innerWidth / 1.35, 300];
  }

  onSelect(event) {
    console.log(event)
    this.visualizarDetalhesProdutosLocal(event.extra.id, event.extra.tipoLocal);
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  percentageFormatting(c) {
    return Math.round(c * 100) / 100;
  }

  carregarDados() {
    this.service.obterGraficoProdutosLocais().subscribe((response: any) => {
      this.graficoProdutosLocais = response;
    });
    this.movimentacaoSolicitacaoService.lista().subscribe((response: any) => {
      this.listaSolicitacoes = response;
    });
    this.movimentacaoSolicitacaoService.listaFeitas().subscribe((response: any) => {
      this.listaSolicitacoesFeitas = response;
    });
    // this.service
    //   .obterTotalizadorProdutoLocalizacao(this.pageTotalizadorLocalizacao - 1, this.pageSizeTotalizadorLocalizacao)
    //   .subscribe((response: PaginaDto) => {
    //     this.listaTotalizadorLocalizacao = response.content;
    //     this.collectionSizeTotalizadorLocalizacao = response.totalElements;
    //   });
    this.movimentacaoHistoricoService
      .paginaTodos(this.page - 1, this.pageSize)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  confirmarSolicitacao(solicitacao: any) {
    const modalOpcoes = this.modalService.open(ConfirmarSolicitacaoComponent, { size: 'md' });
    modalOpcoes.componentInstance.solicitacao = solicitacao;
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
      this.carregarDados();
    });
  }

  relatorioAlertaModal(tipo: string) {
    const modalOpcoes = this.modalService.open(RelatorioAlertaModalComponent, { size: 'md' });
    modalOpcoes.componentInstance.tipo = tipo;
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
    });
  }

  recusarSolicitacao(id: number) {
    const dto = {
      id: id,
      confirmar: false,
      localId: null
    }
    this.utilService.mensagemConfirmacao(`Deseja recusar a solicitação?`, UtilService.MENSAGEM_ALERTA)
      .then((result) => {
        if (result.isConfirmed) {
          this.movimentacaoSolicitacaoService.confirmarSolicitacao(dto).subscribe(res => {
            this.utilService.mensagem(`Solicitação recusada!`, UtilService.MENSAGEM_SUCESSO);
            this.carregarDados();
          }, error => {
            this.utilService.mensagem(
              this.utilService.traduzErro(error),
              UtilService.MENSAGEM_ERRO
            );
          });
        }
      });
  }

  async initMapa(extent?: string) {
    const that = this;

    const featureCollection = await this.service.listaLocalProduto().toPromise();

    console.log(featureCollection)

    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');

    const overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const newProjCode = 'EPSG:4326';
    proj4.defs(newProjCode, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
    register(proj4);
    const newProj = getProjection(newProjCode);

    let view = new View({
      projection: newProj,
      center: [-51.95756207384352, -14.061509571510168],
      zoomFactor: 2,
      zoom: 4,
      rotation: 0
    });

    const raster = new TileLayer({
      source: new OSM(),
    });

    if (featureCollection) {
      this.source = new VectorSource({
        features: new GeoJSON().readFeatures(featureCollection)
      });
    } else {
      this.source = new VectorSource();
    }

    const stylePonto = new Style({
      image: new CircleStyle({
        radius: 17,
        fill: new Fill({ color: '#01d7ff' }),
      })
    });

    var styleFunctionPonto = function (feature) {
      const colors = ['#dc3545', '#ffff00', '#01d7ff']
      let color;
      if (feature.get('diasrestantes') < 0) {
        color = colors[0];
      } else if (feature.get('diasrestantes') < 10) {
        color = colors[1];
      } else {
        color = colors[2];
      }
      return new Style({
        image: new CircleStyle({
          radius: 17,
          fill: new Fill({ color }),
        })
      });
    };

    let styleTexto = new Style({
      text: new Text({
        font: '13px "Roboto"',
        placement: 'point',
        fill: new Fill({
          color: '#070021',
        }),
      }),
    });

    var styleFunctionTexto = function (feature) {
      if (feature) {
        styleTexto.getText().setText(feature ? feature.get('qtd').toString() : '');
      }
      return styleTexto;
    };

    const vectorText = new VectorLayer({
      source: this.source,
      style: styleFunctionTexto,
      zIndex: 3,
    });

    const vector = new VectorLayer({
      source: this.source,
      style: styleFunctionPonto
    });

    this.mapa = new Map({
      controls: defaultControls().extend([new FullScreen()]),
      layers: [raster, vector, vectorText],
      target: 'mapa',
      view,
      overlays: [overlay],
    });

    this.adicionarBotaoSelecionarLocal();

    if (extent) {
      const extentNumber: number[] = JSON.parse(extent);

      this.mapa.getView().fit(extentNumber);

      if (extentNumber[0] === extentNumber[2] && extentNumber[1] === extentNumber[3]) {
        this.mapa.getView().setZoom(18);
      }
    }

    this.mapa.on('singleclick', function (evt) {
      that.mapa.forEachFeatureAtPixel(evt.pixel, (feature) => {
        if (feature.get('id')) {
          const coordinate = evt.coordinate;
          const xlon = coordinate[0];
          const ylat = coordinate[1];
          content.innerHTML = `<b style="color:#070021">Quantidade de produtos: ${feature.get('qtd')}</b><br>
                                <div style="color:#777777; font-size: 14px; padding-bottom:5px;">
                                <b>${feature.get('local')}</b>
                                ${feature.get('nome')}</div>`;
          that.coordenada = feature.getGeometry().getCoordinates();
          overlay.setPosition(coordinate);
          return true;
        }
      });
    });

    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    let currZoom = this.mapa.getView().getZoom();
    this.mapa.on('movestart', function (e) {
      currZoom = that.mapa.getView().getZoom();
    });

    this.mapa.on('moveend', function (e) {
      const newZoom = that.mapa.getView().getZoom();
      if (currZoom < newZoom) {
        overlay.setPosition(undefined);
        closer.blur();
        return false;
      }
    });

  }

  adicionarBotaoSelecionarLocal() {
    const that = this;

    let button = document.createElement('button');

    const salvarExtent = function (e) {
      that.salvarExtent()
    };

    button.addEventListener('click', salvarExtent, false);

    let icone = document.createElement('i');
    icone.className = 'bi bi-check-all';
    button.appendChild(icone);

    let div = document.createElement('div');
    div.className = 'ol-unselectable ol-control selecionar-local';
    div.style.cssText = 'top: 80px; left: .5em;';
    div.appendChild(button);

    this.selecionarLocalControle = new Control({
      element: div,
    });
    this.selecionarLocalControle.set('nome', 'desenho');
    this.mapa.addControl(this.selecionarLocalControle);
  }

  salvarExtent() {
    let extent = this.mapa.getView().calculateExtent(this.mapa.getSize());

    console.log(extent);

    if (this.configuracao) {
      this.configuracao.extent = JSON.stringify(extent);
    } else {
      this.configuracao = {
        extent: JSON.stringify(extent)
      }
    }


    this.configuracaoService.salvar(this.configuracao).subscribe(res => {
      this.utilService.mensagem(`Mapa salvo!`, UtilService.MENSAGEM_SUCESSO);
    })

  }

  abrirGoogleMaps(): void {
    const lat = this.coordenada[1];
    const lng = this.coordenada[0];
    if ((navigator.platform.indexOf('iPhone') !== -1)
      || (navigator.platform.indexOf('iPod') !== -1)
      || (navigator.platform.indexOf('iPad') !== -1)) {
      window.open(`maps://www.google.com/maps/dir/?api=1&travelmode=walking&layer=traffic&destination=${lat},${lng}`);
    } else {
      window.open(`https://www.google.com/maps/dir/?api=1&travelmode=walking&layer=traffic&destination=${lat},${lng}`);
    }
  }

  visualizarSolicitacao(id: number) {
    const modalOpcoes = this.modalService.open(DetalhesSolicitacaoComponent, { size: 'md' });
    modalOpcoes.componentInstance.id = id;
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
    });
  }

  visualizarDetalhesProdutosLocal(id: number, local: string) {
    const modalOpcoes = this.modalService.open(DetalhesProdutoLocalComponent, { size: 'lg' });
    modalOpcoes.componentInstance.id = id;
    modalOpcoes.componentInstance.local = local.toLowerCase();
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
    });
  }
}
