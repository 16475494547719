import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoTipoService } from '../produto-tipo.service';

@Component({
  selector: 'app-produto-tipo-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ProdutoTipoListaComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  tiposProduto: ProdutoTipoDto[] = [];

  constructor(
    private service: ProdutoTipoService,
    private utilService: UtilService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, undefined, undefined)
      .subscribe((response: PaginaDto) => {
        this.tiposProduto = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  editar(id) {
    this.router.navigateByUrl('/produto-tipo/' + id);
  }

  excluir(id: number){
    this.service.excluir(id).subscribe(res => {
      this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    })
  }
}
