import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/parceria`;

@Injectable({
  providedIn: 'root'
})
export class ParceriaService {

  constructor(
    private http: HttpClient
  ) { }

  salvarCoordenada(geometriaDto: any): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar-coordenada`, geometriaDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number, tipo: string): Observable<Object> {
    const filtro = tipo ? `&tipo=${tipo}` : `&tipo=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtro}`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }

  obterDistribuidores(): Observable<Object> {
    return this.http.get(`${API}/obter-distribuidores`);
  }

  obterFornecedores(): Observable<Object> {
    return this.http.get(`${API}/obter-fornecedores`);
  }

}
