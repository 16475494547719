import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AtributoOpcaoDto, ProdutoTipoAtributoDto } from 'src/app/shared/dto/ProdutoTipoAtributoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ModalOpcoesComponent } from '../modal-opcoes/modal-opcoes.component';
import { ProdutoTipoService } from '../produto-tipo.service';

@Component({
  selector: 'app-produto-tipo-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class ProdutoTipoFormularioComponent implements OnInit {

  id;
  loading = false;
  submitted = false;
  atributos: ProdutoTipoAtributoDto[] = [];
  atributoTipos: any[] = [
    {
      nome: 'Campo de texto',
      value: 'campo',
    },
    {
      nome: 'Opções',
      value: 'opcoes',
    },
  ];

  form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
  });

  constructor(
    private service: ProdutoTipoService,
    private utilService: UtilService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
  }

  ngOnInit(): void {
    if (this.id !== 'novo') {
      this.service.obterPorId(this.id).subscribe((res: ProdutoTipoDto) => {
        this.id = res.id;
        this.form.setValue({
          nome: res.nome
        })
        for (const atributo of res.atributos) {
          atributo.opcoesObj = this.service.opcoesParaObj(atributo.opcoes);
        }
        this.atributos = res.atributos;
      })
    }
  }

  get f() {
    return this.form.controls;
  }

  adicionarAtributo() {
    const atributo: ProdutoTipoAtributoDto = {
      nome: '',
      tipo: '',
      ordem: this.atributos.length
    }
    this.atributos.push(atributo);
  }


  excluirAtributo(atributo) {
    this.atributos.forEach((item, index) => {
      if (item === atributo) this.atributos.splice(index, 1);
    });
  }

  subirAtributo(index) {
    if (index > 0) {
      let el = this.atributos[index];
      console.log(index)
      this.atributos[index] = this.atributos[index - 1];
      this.atributos[index - 1] = el;

      this.atributos[index].ordem = index;
      el.ordem = index - 1;
    }
  }

  descerAtributo(index) {
    if (index !== -1 && index < this.atributos.length - 1) {
      let el = this.atributos[index];
      console.log(index)
      this.atributos[index] = this.atributos[index + 1];
      this.atributos[index + 1] = el;

      this.atributos[index].ordem = index;
      el.ordem = index + 1;
    }
  }

  adicionarOpcao(index) {
    const modalOpcoes = this.modalService.open(ModalOpcoesComponent, { size: 'md' });
    if (this.atributos[index].opcoes && this.atributos[index].opcoes !== '') {
      modalOpcoes.componentInstance.opcoes = this.atributos[index].opcoesObj;
    }
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: AtributoOpcaoDto[]) => {
      this.atributos[index].opcoesObj = retorno;
      this.atributos[index].opcoes = this.service.opcoesParaStr(retorno);
    });
  }


  cadastrar() {
    this.submitted = true;
    this.form.markAllAsTouched();
    if (this.loading || this.form.invalid) {
      return;
    }

    const id = (this.id !== 'novo') ? this.id : null;
    const nome: string = this.form.get('nome').value;

    const produtoTipo: ProdutoTipoDto = {
      id,
      nome,
      atributos: this.atributos
    }

    this.loading = true;
    this.service.cadastrar(produtoTipo).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
        this.router.navigateByUrl('/produto-tipo');
      },
      (error) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(error),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }

  cancelar() {
    this.router.navigateByUrl('/produto-tipo');
  }
}
