<div class="d-flex justify-content-between m-2">
    <h4>Histórico de solicitações</h4>
</div>
<div class="formulario-box mb-3">
    <h5 class="mb-3">Solicitações de movimentações recebidas</h5>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Data</th>
                    <th scope="col" class="text-left">Usuário</th>
                    <th scope="col" class="text-left">Solicitante</th>
                    <th scope="col" class="text-left">Aceita</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of listaRecebidas">
                    <td scope="row" class="text-left">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row" class="text-left">{{ item.usuario }}</td>
                    <td scope="row" class="text-left">{{ item.requerente }}</td>
                    <td scope="row" class="text-left">{{ item.aceita ? 'Sim' : 'Não' }}</td>
                    <td scope="row">
                        <button mat-icon-button class="btn-azul mr-2" type="button" (click)="visualizarSolicitacao(item.id)"><i
                class="bi bi-eye"></i></button>
                        <button *ngIf="item.pendente === true" mat-icon-button class="btn-success mr-2" type="button" (click)="confirmarSolicitacao(item)"><i class="bi bi-check-circle"></i></button>
                        <button *ngIf="item.pendente === true" mat-icon-button class="btn-warn" type="button" (click)="recusarSolicitacao(item.id)"><i class="bi bi-x-circle"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSizeRecebidas" [pageSize]="pageSizeRecebidas" [(page)]="pageRecebidas" [maxSize]="pageSizeRecebidas" [rotate]="true" (pageChange)="carregarPaginaRecebidas($event)">
    </ngb-pagination>
</div>
<div class="formulario-box mb-3">
    <h5 class="mb-3">Solicitações de movimentações feitas</h5>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Data</th>
                    <th scope="col" class="text-left">Usuário</th>
                    <th scope="col" class="text-left">Destino</th>
                    <th scope="col" class="text-left">Aceita</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of listaFeitas">
                    <td scope="row" class="text-left">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row" class="text-left">{{ item.usuario }}</td>
                    <td scope="row" class="text-left">{{ item.empresa }}</td>
                    <td scope="row" class="text-left">{{ item.aceita ? 'Sim' : 'Não' }}</td>
                    <td scope="row">
                        <button mat-icon-button class="btn-azul mr-2" type="button" (click)="visualizarSolicitacao(item.id)"><i
                class="bi bi-eye"></i></button>
                        <button *ngIf="item.pendente === true" mat-icon-button class="btn-warn" type="button" (click)="recusarSolicitacao(item.id)"><i class="bi bi-x-circle"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSizeFeitas" [pageSize]="pageSizeFeitas" [(page)]="pageFeitas" [maxSize]="pageSizeFeitas" [rotate]="true" (pageChange)="carregarPaginaFeitas($event)">
    </ngb-pagination>
</div>