<div class="modal-header">
    <h4 class="modal-title">Detalhes da solicitação</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">
    <p>A movimentação contém o total de {{collectionSize}} produtos.</p>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Código</th>
                    <th scope="col" class="text-left">Tipo</th>
                    <th scope="col" class="text-left">Especificação</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row" class="text-left">{{ item.codigo }}</td>
                    <td scope="row" class="text-left">{{ item.tipo }}</td>
                    <td scope="row" class="text-left">{{ item.especificacao }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize" [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>
<div class="modal-footer">
    <button mat-raised-button color="warn" type="button" (click)="modal.close()">Cancelar</button>
</div>