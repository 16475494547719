<div class="modal-header">
    <h4 class="modal-title">Opções</h4>
    <button type="button" class="btn" aria-label="Close" (click)="modal.close()"><i class="bi bi-x"></i></button>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col-8">
            Nome
        </div>
        <div class="col-4 text-center">
            Ações
        </div>
        <ng-container *ngFor="let opcao of opcoes; let i = index">
            <div class="form-group col-8">
                <input type="text" class="form-control" [(ngModel)]="opcao.nome" name="nome{{i}}">
            </div>
            <div class="form-group col-4 acoes">
                <i class="bi bi-arrow-up-circle p-2" (click)="subir(i)"></i>
                <i class="bi bi-arrow-down-circle p-2" (click)="descer(i)"></i>
                <i class="bi bi-trash3 p-2" (click)="excluir(opcao)"></i>
            </div>
        </ng-container>
    </div>

    <div class="col-12 text-center">
        <a class="btn btn-primary" (click)="adicionar()">Adicionar</a>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="salvar()">Salvar</button>
</div>
