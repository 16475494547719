import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/local-produto`;

@Injectable({
  providedIn: 'root'
})
export class LocalProdutoService {

  constructor(
    private http: HttpClient
  ) { }

  pesquisarEndereco(endereco) {
    return this.http.get(API + `/pesquisar-endereco?endereco=${endereco}`);
  }

}
