<div class="d-flex justify-content-between m-2">
    <h4>Relatório Totalizador Movimentações</h4>
</div>
<div class="formulario-box">
    <div class="row">
        <div class="col-xs-12 col-sm-2 form-group">
            <mat-form-field class="w-100">
                <mat-label>Data inicial</mat-label>
                <input matInput [(ngModel)]="dataInicial" placeholder="00/00/0000 00:00" mask="00/00/0000 00:00">
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-2 form-group">
            <mat-form-field class="w-100">
                <mat-label>Data final</mat-label>
                <input matInput [(ngModel)]="dataFinal" placeholder="00/00/0000 00:00" mask="00/00/0000 00:00">
            </mat-form-field>
        </div>
    </div>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="imprimir()"><mat-icon>print</mat-icon>
            imprimir</button>
    </div>
</div>