import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { ProdutoService } from '../../produto/produto.service';


@Component({
  selector: 'relatorio-codigo-produtos',
  templateUrl: './relatorio-codigo-produtos.component.html',
  styleUrls: ['./relatorio-codigo-produtos.component.scss']
})
export class RelatorioCodigoProdutosComponent implements OnInit {

  loading = false;
  submitted = false;
  produtoTipos: ProdutoTipoDto[] = [];

  form = new FormGroup({
    tipo: new FormControl(''),
  });

  constructor(
    private service: ProdutoService,
    private produtoTipoService: ProdutoTipoService,
  ) { }


  ngOnInit(): void {
    this.listaProdutoTipo();
  }

  get f() {
    return this.form.controls;
  }

  async listaProdutoTipo() {
    const produtosTipos: any = await this.produtoTipoService.lista().toPromise();
    this.produtoTipos = produtosTipos;
    const produtosTiposFornecedor = await this.produtoTipoService.listaFornecedor().toPromise();
    this.produtoTipos = this.produtoTipos.concat(produtosTiposFornecedor);
  }

  gerarExcel(): void {
    const tipoId: number = Number(this.form.get('tipo').value);

    this.service
      .relatorioCodigoProdutosExcel(tipoId)
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = 'relatorio-codigo-produtos.xlsx';
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveBlob(blob, filename);
        } else {
          var elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      });
  }
}