<div class="d-flex justify-content-between m-2">
    <h4>Relatório Código dos Produtos</h4>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let tipo of produtoTipos" [value]="tipo.id"> {{tipo.nome}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="gerarExcel()"><mat-icon>print</mat-icon>
            Gerar Excel</button>
    </div>
</div>