import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class UtilService {

  public static API = '/api';

  public static MENSAGEM_SUCESSO = 'success';
  public static MENSAGEM_ERRO = 'error';
  public static MENSAGEM_ALERTA = 'warning';
  public static MENSAGEM_INFORMACAO = 'info';

  constructor() { }

  traduzErro(erro: any): any {
    if (erro.error && erro.error.message) {
      return erro.error.message;
    }
    if (erro.error) {
      return erro.error;
    }
    return erro;
  }

  mensagem(mensagem, tipo, tempo?: number): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: tempo ? tempo : 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: tipo,
      title: mensagem,
    });
  }

  mensagemConfirmacao(mensagem, tipo): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: mensagem,
      icon: tipo,
      showCancelButton: true,
      confirmButtonColor: '#344F7D',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });
  }

  isValidDate(dateString: string, pattern: any): boolean {
    if (!pattern.test(dateString)) {
      console.log('patenr erro')
      return false;
    }
    const parts = dateString.match(pattern);
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[2], 10);
    const year = parseInt(parts[3], 10);
    const hours = parseInt(parts[4], 10);
    const minutes = parseInt(parts[5], 10);
    if (month < 1 || month > 12 || day < 1 || day > 31 || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return false;
    }
    const date = new Date(year, month - 1, day, hours, minutes);
    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
      return false;
    }
    return true;
  }

}
