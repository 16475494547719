import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalOpcoesComponent } from './modal-opcoes/modal-opcoes.component';
import { ProdutoTipoListaComponent } from './lista/lista.component';
import { ProdutoTipoFormularioComponent } from './formulario/formulario.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [
    ProdutoTipoListaComponent,
    ProdutoTipoFormularioComponent,
    ModalOpcoesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgbModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule
  ],
  exports: [
    ProdutoTipoListaComponent,
    ProdutoTipoFormularioComponent
  ]
})
export class ProdutoTipoModule { }
