import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoDescricaoDto } from 'src/app/shared/dto/ProdutoDescricaoDto';
import { ProdutoDescricaoEspecificacaoDto } from 'src/app/shared/dto/ProdutoDescricaoEspecificacaoDto';
import { MovimentacaoSolicitacaoService } from 'src/app/shared/service/movimentacao-solicitacao.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';

@Component({
  selector: 'app-detalhes-solicitacao',
  templateUrl: './detalhes-solicitacao.component.html',
  styleUrls: ['./detalhes-solicitacao.component.scss']
})
export class DetalhesSolicitacaoComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() id: number;

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ProdutoDescricaoEspecificacaoDto[] = [];

  constructor(
    public modal: NgbActiveModal,
    private movimentacaoSolicitacaoService: MovimentacaoSolicitacaoService,
    private produtoLocalService: ProdutoLocalService,
    private utilService: UtilService
  ) { }


  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.movimentacaoSolicitacaoService
      .listaItensMovimentacao(this.page - 1, this.pageSize, this.id)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }


}
