<div class="d-flex justify-content-between m-2">
    <h4>Cliente</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="editar('novo')"><i class="bi bi-plus-circle"></i>
        Novo</button>
</div>
<div class="formulario-box">
    <div class="row">
        <div class="col-xs-12 col-sm-4 form-group">
            <mat-form-field class="w-100">
                <mat-label>Nome</mat-label>
                <input type="text" matInput [(ngModel)]="nome" name="nome">
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-4 form-group">
            <mat-form-field class="w-100">
                <mat-label>Endereço</mat-label>
                <input type="text" matInput [(ngModel)]="endereco" name="endereco">
            </mat-form-field>
        </div>
        <div class="btn-mob">
            <button mat-raised-button class="btn-azul" type="button" (click)="carregarDados()"><i
                    class="bi bi-search"></i></button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col">Id</th>
                    <th scope="col" class="text-left">Nome</th>
                    <th scope="col" class="text-left">Endereço</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row">{{ item.id }}</td>
                    <td scope="row" class="text-left">{{ item.nome }}</td>
                    <td scope="row" class="text-left">{{ item.endereco }}</td>
                    <td scope="row" class="acoes">
                        <button mat-icon-button class="btn-azul mr-2" type="button" (click)="editar(item.id)"><i
                                class="bi bi-pencil-square"></i></button>
                        <button mat-icon-button class="btn-warn" type="button" (click)="excluir(item.id)"><i
                                class="bi bi-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize"
        [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>