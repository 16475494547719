import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParceriaTokenDto } from 'src/app/shared/dto/ParceriaTokenDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ParceriaTokenService } from '../parceria-token.service';
import { ParceriaService } from '../parceria.service';

@Component({
  selector: 'app-adicionar-token',
  templateUrl: './adicionar-token.component.html',
  styleUrls: ['./adicionar-token.component.scss']
})
export class AdicionarTokenComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ParceriaTokenDto[] = [];

  submitted = false;
  form = new FormGroup({
    token: new FormControl('', [Validators.required]),
  });

  constructor(
    public modal: NgbActiveModal,
    private service: ParceriaTokenService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
  }

  get f() {
    return this.form.controls;
  }

  validar() {
    const token: string = this.form.get('token').value;
    this.service.validar(token).subscribe(data => {
      this.utilService.mensagem('Fornecedor adicionado!', UtilService.MENSAGEM_SUCESSO);
      this.passEntry.emit('retorno');
      this.modal.close();
    }, error => {
      this.utilService.mensagem(this.utilService.traduzErro(error), UtilService.MENSAGEM_ERRO)
    });
  }

}
