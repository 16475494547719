import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrdemServicoItensDto } from 'src/app/shared/dto/OrdemServicoItensDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/ordemservico`;

@Injectable({
  providedIn: 'root'
})
export class OrdemServicoService {


  constructor(
    private http: HttpClient
  ) { }

  cadastrar(ordemServicoItensDto: OrdemServicoItensDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar-ordem-servico-itens`, ordemServicoItensDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number, id: number): Observable<Object> {
    const filtroId = id ? `&id=${id}` : `&id=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtroId}`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/obter-ordem-servico-itens/${id}`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }
}
