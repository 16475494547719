<div *ngIf="role === 'administrador'" class="d-flex justify-content-between m-2">
    <h4>Usuário</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i>
    Voltar</button>
</div>
<div *ngIf="role === 'administrador'" class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-6">
            <mat-form-field class="w-100">
                <mat-label>Nome</mat-label>
                <input type="text" matInput formControlName="nome">
                <mat-error *ngIf="f.nome.hasError('required')"> Nome é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-6">
            <mat-form-field class="w-100">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email">
                <mat-error *ngIf="f.email.hasError('required')"> Email é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-4">
            <mat-form-field class="w-100">
                <mat-label>Código</mat-label>
                <input type="text" matInput formControlName="codigo">
                <mat-error *ngIf="f.codigo.hasError('required')"> Código é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-4">

            <mat-form-field class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option *ngFor="let tipo of tipos" [value]="tipo.value"> {{tipo.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.tipo.hasError('required')"> Tipo é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-4">
            <mat-form-field class="w-100">
                <mat-label>Senha</mat-label>
                <input type="password" matInput formControlName="senha">
                <mat-error *ngIf="f.senha.hasError('required')"> Senha é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="cadastrar()">Salvar</button>
        <button mat-raised-button color="warn" type="button" (click)="cancelar()">Cancelar</button>
    </div>
</div>
