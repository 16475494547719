import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/etiqueta`;

@Injectable({
  providedIn: 'root'
})
export class EtiquetaService {

  constructor(
    private http: HttpClient
  ) { }

  gerar(codigos: string[]): Observable<HttpResponse<Blob>> {
    return this.http.post(`${API}/gerar`, codigos, {
      observe: 'response',
      responseType: 'blob'
    });
  }

}
