import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertaDto } from 'src/app/shared/dto/AlertaDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoDescricaoDto } from 'src/app/shared/dto/ProdutoDescricaoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { AlertaService } from '../alerta.service';

@Component({
  selector: 'app-alerta-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class AlertaListaComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: AlertaDto[] = [];

  constructor(
    private service: AlertaService,
    private utilService: UtilService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  editar(id) {
    this.router.navigateByUrl('/alerta/' + id);
  }

  excluir(id: number) {
    this.service.excluir(id).subscribe(res => {
      this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    })
  }
}
