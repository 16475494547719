import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoDescricaoDto } from 'src/app/shared/dto/ProdutoDescricaoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { UsuarioService } from '../../usuario/usuario.service';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { ClienteService } from '../../cliente/cliente.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-movimentacao-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
})
export class MovimentacaoListaComponent implements OnInit {

  mostrarClientes: boolean;
  mostrarEntregador: boolean;

  responsaveis: UsuarioDto[] = [];
  clientes: ClienteDto[] = [];
  clientesFiltrados: ClienteDto[] = [];
  locais: LocalDto[] = [{
    id: -1,
    nome: 'Responsável'
  },
  {
    id: 0,
    nome: 'Cliente'
  }];

  form = new FormGroup({
    local: new FormControl(''),
    entregador: new FormControl(''),
    cliente: new FormControl(''),
    usuario: new FormControl(''),
    dataInicial: new FormControl(''),
    dataFinal: new FormControl(''),
    entrada: new FormControl('true'),
    saida: new FormControl('true'),
  });

  previousEntradaState: boolean = false;
  previousSaidaState: boolean = false;

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ProdutoDescricaoDto[] = [];
  produtoTipos: ProdutoTipoDto[] = [];
  active = 1;

  constructor(
    private service: MovimentacaoHistoricoService,
    private movimentacaoHistoricoService: MovimentacaoHistoricoService,
    private utilService: UtilService,
    private usuarioService: UsuarioService,
    private produtoLocalService: ProdutoLocalService,
    private clienteService: ClienteService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregarDados();
    this.listaLocal();
    this.listaCliente();
    this.listaEntregadores();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  montarFiltro(): string {
    const dataFinal = this.form.get('dataFinal').value;
    const dataInicial = this.form.get('dataInicial').value;
    const usuario = this.form.get('usuario').value;
    const local = this.form.get('local').value;
    const cliente = this.form.get('cliente').value;
    const entregador = this.form.get('entregador').value;
    const entrada = String(this.form.get('entrada').value);
    const saida = String(this.form.get('saida').value);

    const pattern = /^(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})$/;
    let dataInicialValida = true;
    let dataFinalValida = true;
    let filtro: string = '';
    if (usuario && usuario !== '') {
      filtro += `usuario:${usuario}`;
    }
    if (local && local !== '' && this.mostrarClientes === false && this.mostrarEntregador === false) {
      if (entrada === 'true' && saida === 'true') {
        filtro += `,localsaida:${local} or localentrada:${local}`;
      } else if (entrada === 'true') {
        filtro += `,localentrada:${local}`;
      } else if (saida === 'true') {
        filtro += `,localsaida:${local}`;
      }
    } else if (this.mostrarClientes === true && cliente) {
      if (entrada === 'true' && saida === 'true') {
        filtro += `,clientesaida:${cliente.id} or clienteentrada:${cliente.id}`;
      } else if (entrada === 'true') {
        filtro += `,clienteentrada:${cliente.id}`;
      } else if (saida === 'true') {
        filtro += `,clientesaida:${cliente.id}`;
      }
    } else if (this.mostrarEntregador === true && entregador) {
      if (entrada === 'true' && saida === 'true') {
        filtro += `,entregadorsaida:${entregador} or entregadorentrada:${entregador}`;
      } else if (entrada === 'true') {
        filtro += `,entregadorentrada:${entregador}`;
      } else if (saida === 'true') {
        filtro += `,entregadorsaida:${entregador}`;
      }
    } else if (this.mostrarClientes === true && !cliente) {
      if (entrada === 'true' && saida === 'true') {
        filtro += `,clientesaida<>null or clienteentrada<>null`;
      } else if (entrada === 'true') {
        filtro += `,clienteentrada<>null`;
      } else if (saida === 'true') {
        filtro += `,clientesaida<>null`;
      }
    } else if (this.mostrarEntregador === true && !entregador) {
      if (entrada === 'true' && saida === 'true') {
        filtro += `,entregadorsaida<>null or entregadorentrada<>null`;
      } else if (entrada === 'true') {
        filtro += `,entregadorentrada<>null`;
      } else if (saida === 'true') {
        filtro += `,entregadorsaida<>null`;
      }
    }

    if (dataInicial && dataInicial !== '' && dataFinal && dataFinal !== '') {
      dataInicialValida = this.utilService.isValidDate(dataInicial, pattern);
      dataFinalValida = this.utilService.isValidDate(dataFinal, pattern);
      filtro += `,datahora>${dataInicial},datahora<${dataFinal}`;
    } else if (dataInicial && dataInicial !== '') {
      dataInicialValida = this.utilService.isValidDate(dataInicial, pattern);
      filtro += `,datahora>${dataInicial}`;
    } else if (dataFinal && dataFinal !== '') {
      dataFinalValida = this.utilService.isValidDate(dataFinal, pattern);
      filtro += `,datahora<${dataFinal}`;
    }
    if (dataInicialValida === false || dataFinalValida === false) {
      this.utilService.mensagem('Verifique se as datas estão corretas!', UtilService.MENSAGEM_ERRO);
    }
    return filtro;
  }

  pdf() {
    let tipoMovimentacao = String(this.form.get('entrada').value) === 'true' ? 'entrada' : '';
    tipoMovimentacao += String(this.form.get('saida').value) === 'true' ? 'saida' : '';

    const filtro = this.montarFiltro();
    this.service
      .relatorioPdf(filtro, tipoMovimentacao)
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/pdf',
        });
        const filename = 'relatorio-movimentacoes.pdf';
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveBlob(blob, filename);
        } else {
          var elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      });
  }

  carregarDados() {
    const filtro = this.montarFiltro();
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, filtro)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  ajustaFiltroEntradaSaida(tipo: string) {
    const entrada = String(this.form.get('entrada').value);
    const saida = String(this.form.get('saida').value);

    if (tipo === 'entrada' && entrada === 'false') {
      this.form.patchValue({
        saida: 'true'
      });
    } else if (tipo === 'saida' && saida === 'false') {
      this.form.patchValue({
        entrada: 'true'
      });
    }
  }

  editar(id) {
    this.router.navigateByUrl('/movimentacao/' + id);
  }

  alterarLocal(id) {
    this.mostrarClientes = id === 0 || id === '0';
    this.mostrarEntregador = id === -1 || id === '-1';
  }

  listaEntregadores() {
    this.usuarioService.listaEntregadores().subscribe((res: UsuarioDto[]) => {
      this.responsaveis = [{
        id: 0,
        nome: 'Todos'
      }, ...res];
    }, error => {
      console.log(error);
    })
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
    }, error => {
      console.log(error);
    })
  }

  listaCliente() {
    this.clienteService.lista().subscribe((res: ClienteDto[]) => {
      this.clientes = res;
      this.clientesFiltrados = res;
    }, error => {
      console.log(error);
    })
  }

  filterCliente(val) {
    let filter = this.clientes.filter((cliente) => cliente.nome.toLowerCase().indexOf(val.toLowerCase()) !== -1);
    this.clientesFiltrados = [...filter];
  }

  selectedclient(event) {
    console.log(event.option.value);
  }

  getOptionText(option) {
    return option.nome;
  }
}
