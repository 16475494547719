<div class="d-flex justify-content-between m-2">
    <h4>Ordem de Serviço</h4>
    <button class="btn btn-primary" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i> Voltar</button>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-4">
            <label class="form-label" for="responsavel">Responsável</label>
            <select formControlName="responsavel" id="responsavel" class="form-control">
        <option *ngFor="let responsavel of responsaveis" [value]="responsavel.id"> {{responsavel.nome}} </option>
      </select>
            <div *ngIf="submitted && f.responsavel.invalid" class="alert alert-danger"> Responsável é obrigatório! </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <label class="form-label" for="dataHora">Data</label>
            <input type="text" formControlName="dataHora" id="dataHora" class="form-control" placeholder="01/01/1999 12:01:01" mask="d0/M0/0000 Hh:m0:s0" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" />
            <div *ngIf="submitted && f.dataHora.invalid" class="alert alert-danger">Data é obrigatório!</div>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <label class="form-label" for="encerramento">Encerramento</label>
            <input type="text" formControlName="encerramento" id="encerramento" class="form-control" placeholder="01/01/1999 12:01:01" mask="d0/M0/0000 Hh:m0:s0" [leadZeroDateTime]="true" [dropSpecialCharacters]="false" />
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <label class="form-label" for="local">Local</label>
            <select class="form-control" id="local" formControlName="local" (change)="alterarLocal($event.target.value)">
        <option *ngFor="let local of locais" value="{{local.id}}"> {{local.nome}} </option>
      </select>
        </div>
        <div *ngIf="mostrarClientes" class="form-group col-xs-12 col-sm-4">
            <label class="form-label" for="cliente">Cliente</label>
            <select class="form-control" id="cliente" formControlName="cliente">
        <option *ngFor="let cliente of clientes" value="{{cliente.id}}"> {{cliente.nome}} </option>
      </select>
            <div *ngIf="submitted && f.cliente.invalid" class="alert alert-danger"> Cliente é obrigatório! </div>
        </div>
    </form>
    <div class="row">
        <div class="col-xs-12 col-sm-2"> Quantidade </div>
        <div class="col-xs-12 col-sm-4"> Tipo de Produto </div>
        <div class="col-xs-12 col-sm-4"> Descrição </div>
        <div class="col-xs-12 col-sm-2 text-center"> Ações </div>
        <ng-container *ngFor="let item of itens; let i = index">
            <div class="form-group col-xs-12 col-sm-2">
                <input type="text" class="form-control" [(ngModel)]="item.quantidade" name="nome{{i}}">
            </div>
            <div class="form-group col-xs-12 col-sm-4">
                <div class="input-group">
                    <select class="form-control" [(ngModel)]="item.produtoTipoId" name="produtoTipoId{{i}}">
            <option *ngFor="let produtoTipo of produtoTipos" value="{{produtoTipo.id}}"> {{produtoTipo.nome}} </option>
          </select>
                </div>
            </div>
            <div class="form-group col-xs-12 col-sm-4">
                <input type="text" class="form-control" [(ngModel)]="item.descricao" name="descricao{{i}}">
            </div>
            <div class="form-group col-xs-12 col-sm-2 acoes">
                <i class="bi bi-arrow-up-circle p-2" (click)="subirItem(i)"></i>
                <i class="bi bi-arrow-down-circle p-2" (click)="descerItem(i)"></i>
                <i class="bi bi-trash3 p-2" (click)="excluirItem(item)"></i>
            </div>
        </ng-container>
    </div>
    <div class="col-12 text-center">
        <a class="btn btn-primary" (click)="adicionarItem()">Adicionar Item</a>
    </div>
    <div class="rodape-form">
        <button class="btn btn-primary mr-2" type="button" (click)="cadastrar()">Salvar</button>
        <button class="btn btn-danger" type="button" (click)="cancelar()">Cancelar</button>
    </div>
</div>