import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/movimentacao-historico`;

@Injectable({
  providedIn: 'root'
})
export class MovimentacaoHistoricoService {

  constructor(
    private http: HttpClient
  ) { }

  paginaTodos(pagina: number, tamanho: number): Observable<Object> {
    return this.http.get(`${API}/pagina-todos?page=${pagina}&size=${tamanho}&sort=id,desc`);
  }

  paginaFiltrar(pagina: number, tamanho: number, search: string): Observable<Object> {
    const filtro = search ? `&search=${search}` : `&search=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtro}`);
  }

  relatorioTotalizadorMovimentacao(search: string): Observable<HttpResponse<Blob>> {
    const filtro = search ? `?search=${search}` : `?search=`;
    return this.http.get(`${API}/relatorio-totalizador-entregador${filtro}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  relatorioPdf(search: string, tipoMovimentacao: string): Observable<HttpResponse<Blob>> {
    const filtro = search ? `?search=${search}` : `?search=`;
    return this.http.get(`${API}/relatorio-pdf${filtro}&tipomovimentacao=${tipoMovimentacao}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
