<mat-toolbar [ngClass]="{'blue': mobileQuery.matches, 'white' : !mobileQuery.matches }">
    <button mat-icon-button (click)="toggleMenu()"><mat-icon>menu</mat-icon></button>
    <div class="header">
        <div *ngIf="!mobileQuery.matches">
            <button mat-button [matMenuTriggerFor]="vertebrates"> {{empresaLogada ? empresaLogada.nome : ''}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
            <mat-menu #vertebrates="matMenu">
                <button mat-menu-item *ngFor="let empresa of empresas" (click)="alterarEmpresa(empresa.codigo)">{{empresa.nome}}</button>
            </mat-menu>
        </div>
        <div>
            <button mat-button [matMenuTriggerFor]="animals"> {{usuarioLogado}} <mat-icon>arrow_drop_down</mat-icon>
      </button>
            <mat-menu #animals="matMenu" yPosition="above" xPosition="before">
                <button mat-menu-item>Perfil</button>
                <button mat-menu-item (click)="sair()">Sair</button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
<mat-toolbar *ngIf="mobileQuery.matches" class="white">
    <div class="header">
        <div>
            <button mat-button [matMenuTriggerFor]="vertebrates"> {{empresaLogada ? empresaLogada.nome : ''}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
            <mat-menu #vertebrates="matMenu">
                <button mat-menu-item *ngFor="let empresa of empresas" (click)="alterarEmpresa(empresa.codigo)">{{empresa.nome}}</button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
