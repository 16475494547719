<div class="d-flex justify-content-between m-2">
    <h4>Tipo de Produto</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i>
        Voltar</button>
</div>
<div class="formulario-box">
    <form *ngIf="!loading" [formGroup]="form">
        <div class="form-group mb-4 text-left">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Nome</mat-label>
                <input type="text" matInput formControlName="nome">
                <mat-error *ngIf="f.nome.hasError('required')"> Nome é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="scroll-horizontal">
        <div class="row mb-2">
            <div class="col-5"> Nome </div>
            <div class="col-4"> Tipo </div>
            <div class="col-3 text-center"> Ações </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let atributo of atributos; let i = index">
                <div class="form-group col-5">
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Nome</mat-label>
                        <input matInput [(ngModel)]="atributo.nome" name="nome{{i}}">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <div class="button-group">
                        <mat-form-field appearance="fill" class="w-80 mr-2">
                            <mat-label>Tipo</mat-label>
                            <mat-select [(ngModel)]="atributo.tipo" name="tipo{{i}}" required>
                                <mat-option *ngFor="let atributoTipo of atributoTipos" [value]="atributoTipo.value">
                                    {{atributoTipo.nome}} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button *ngIf="atributo.tipo === 'opcoes'" (click)="adicionarOpcao(i)" mat-icon-button
                            class="btn-azul" aria-label="Editar opções">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="form-group col-3 acoes">
                    <i class="bi bi-arrow-up-circle p-2" (click)="subirAtributo(i)"></i>
                    <i class="bi bi-arrow-down-circle p-2" (click)="descerAtributo(i)"></i>
                    <i class="bi bi-trash3 p-2" (click)="excluirAtributo(atributo)"></i>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col-12 adicionar-atributo">
        <button mat-raised-button class="btn-azul" type="button" (click)="adicionarAtributo()">
            <mat-icon>add</mat-icon> Adicionar Atributo </button>
    </div>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="cadastrar()">Salvar</button>
        <button mat-raised-button color="warn" type="button" (click)="cancelar()">Cancelar</button>
    </div>
</div>