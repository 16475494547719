import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/movimentacao-solicitacao`;

@Injectable({
  providedIn: 'root'
})
export class MovimentacaoSolicitacaoService {

  constructor(
    private http: HttpClient
  ) { }

  lista(): Observable<Object> {
    return this.http.get(`${API}/lista`);
  }

  listaFeitas(): Observable<Object> {
    return this.http.get(`${API}/lista-feitas`);
  }

  todas(pagina: number, tamanho: number): Observable<Object> {
    return this.http.get(`${API}/todas?page=${pagina}&size=${tamanho}`);
  }

  todasFeitas(pagina: number, tamanho: number): Observable<Object> {
    return this.http.get(`${API}/todas-feitas?page=${pagina}&size=${tamanho}`);
  }

  confirmarSolicitacao(dto: any): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/confirmar-solicitacao`, dto, options);
  }

  listaItensMovimentacao(pagina: number, tamanho: number, id: number): Observable<Object> {
    return this.http.get(`${API}/pagina-itens-movimentacao?page=${pagina}&size=${tamanho}&sort=id,desc&id=${id}`);
  }

}
