import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { AlertaFormularioComponent } from './painel/paginas/alerta/formulario/formulario.component';
import { AlertaListaComponent } from './painel/paginas/alerta/lista/lista.component';
import { ClienteFormularioComponent } from './painel/paginas/cliente/formulario/formulario.component';
import { ClienteListaComponent } from './painel/paginas/cliente/lista/lista.component';
import { EtiquetaListaComponent } from './painel/paginas/etiqueta/lista/lista.component';
import { HistoricoSolicitacaoComponent } from './painel/paginas/historico-solicitacao/historico-solicitacao.component';
import { OrdemServicoFormularioComponent } from './painel/paginas/ordemservico/formulario/formulario.component';
import { OrdemServicoListaComponent } from './painel/paginas/ordemservico/lista/lista.component';
import { ParceriaComponent } from './painel/paginas/parceria/parceria.component';
import { ProdutoLocalFormularioComponent } from './painel/paginas/produto-local/formulario/formulario.component';
import { ProdutoLocalListaComponent } from './painel/paginas/produto-local/lista/lista.component';
import { ProdutoTipoFormularioComponent } from './painel/paginas/produto-tipo/formulario/formulario.component';
import { ProdutoTipoListaComponent } from './painel/paginas/produto-tipo/lista/lista.component';
import { ProdutoFormularioComponent } from './painel/paginas/produto/formulario/formulario.component';
import { ProdutoListaComponent } from './painel/paginas/produto/lista/lista.component';
import { UsuarioFormularioComponent } from './painel/paginas/usuario/formulario/formulario.component';
import { UsuarioListaComponent } from './painel/paginas/usuario/lista/lista.component';
import { VisaoGeralComponent } from './painel/paginas/visao-geral/visao-geral.component';
import { PainelComponent } from './painel/painel.component';
import { ConfiguracaoEmpresaFormularioComponent } from './painel/paginas/configuracao-empresa/formulario/formulario.component';
import { RelatorioTotalizadorProdutoFormularioComponent } from './painel/paginas/relatorio/relatorio-totalizador-produto/relatorio-totalizador-produto.component';
import { RelatorioCodigoProdutosComponent } from './painel/paginas/relatorio/relatorio-codigo-produtos/relatorio-codigo-produtos.component';
import { MovimentacaoListaComponent } from './painel/paginas/movimentacao/lista/lista.component';
import { MovimentacaoFormularioComponent } from './painel/paginas/movimentacao/formulario/formulario.component';
import { RelatorioTotalizadorEntregadorComponent } from './painel/paginas/relatorio/relatorio-totalizador-entregador/relatorio-totalizador-entregador.component';

const routes: Routes = [
  {
    path: '',
    component: PainelComponent,
    children: [
      { path: 'visao-geral', component: VisaoGeralComponent },
      { path: 'historico-solicitacao', component: HistoricoSolicitacaoComponent },
      { path: 'produto-tipo', component: ProdutoTipoListaComponent },
      { path: 'produto-tipo/:id', component: ProdutoTipoFormularioComponent },
      { path: 'produto', component: ProdutoListaComponent },
      { path: 'produto/:id', component: ProdutoFormularioComponent },
      { path: 'movimentacao', component: MovimentacaoListaComponent },
      { path: 'movimentacao/:id', component: MovimentacaoFormularioComponent },
      { path: 'cliente', component: ClienteListaComponent },
      { path: 'cliente/:id', component: ClienteFormularioComponent },
      { path: 'local', component: ProdutoLocalListaComponent },
      { path: 'local/:id', component: ProdutoLocalFormularioComponent },
      { path: 'usuario', component: UsuarioListaComponent },
      { path: 'usuario/:id', component: UsuarioFormularioComponent },
      { path: 'alerta', component: AlertaListaComponent },
      { path: 'alerta/:id', component: AlertaFormularioComponent },
      { path: 'ordem-servico', component: OrdemServicoListaComponent },
      { path: 'ordem-servico/:id', component: OrdemServicoFormularioComponent },
      { path: 'parceria', component: ParceriaComponent },
      { path: 'etiqueta', component: EtiquetaListaComponent },
      { path: 'configuracao-empresa', component: ConfiguracaoEmpresaFormularioComponent },
      { path: 'relatorio-totalizador-produto', component: RelatorioTotalizadorProdutoFormularioComponent },
      { path: 'relatorio-codigo-produtos', component: RelatorioCodigoProdutosComponent },
      { path: 'relatorio-totalizador-entregador', component: RelatorioTotalizadorEntregadorComponent },
      {
        path: '',
        redirectTo: 'visao-geral',
        pathMatch: 'full'
      }
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
