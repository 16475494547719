import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/parceria-token`;

@Injectable({
  providedIn: 'root'
})
export class ParceriaTokenService {

  constructor(
    private http: HttpClient
  ) { }

  gerar(): Observable<Object> {
    const options = { responseType: 'text' as 'json' };
    return this.http.get(`${API}/gerar`, options);
  }

  validar(token: string): Observable<Object> {
    const options = { responseType: 'text' as 'json' };
    return this.http.get(`${API}/validar?token=${token}`, options);
  }

  paginaFiltrar(pagina: number, tamanho: number): Observable<Object> {
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }

}
