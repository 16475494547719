import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ProdutoTipoModule } from './produto-tipo/produto-tipo.module';
import { ProdutoModule } from './produto/produto.module';
import { ClienteModule } from './cliente/cliente.module';
import { ProdutoLocalModule } from './produto-local/produto-local.module';
import { UsuarioModule } from './usuario/usuario.module';
import { OrdemServicoModule } from './ordemservico/ordemservico.module';
import { VisaoGeralModule } from './visao-geral/visao-geral.module';
import { ParceriaModule } from './parceria/parceria.module';
import { EtiquetaModule } from './etiqueta/etiqueta.module';
import { HistoricoSolicitacaoModule } from './historico-solicitacao/historico-solicitacao.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { AlertaModule } from './alerta/alerta.module';
import { ConfiguracaoEmpresaModule } from './configuracao-empresa/configuracao-empresa.module';
import { RelatorioModule } from './relatorio/relatorio.module';
import { MovimentacaoModule } from './movimentacao/movimentacao.module';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    ProdutoTipoModule,
    ProdutoModule,
    ClienteModule,
    ProdutoLocalModule,
    UsuarioModule,
    OrdemServicoModule,
    VisaoGeralModule,
    ParceriaModule,
    EtiquetaModule,
    HistoricoSolicitacaoModule,
    AlertaModule,
    ConfiguracaoEmpresaModule,
    RelatorioModule,
    MovimentacaoModule,
  ],
  exports: [],
})
export class PaginasModule { }
