import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-cliente-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ClienteListaComponent implements OnInit {

  nome: string;
  endereco: string;

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ClienteDto[] = [];

  constructor(
    private service: ClienteService,
    private utilService: UtilService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    let filtro: string = '';
    if (this.nome && this.nome !== '') {
      filtro = `nome:${this.nome}`;
    }
    if (this.endereco && this.endereco !== '') {
      filtro = `endereco:${this.endereco}`;
    }
    this.service
      .paginaFiltrar(this.page - 1, this.pageSize, filtro)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  editar(id) {
    this.router.navigateByUrl('/cliente/' + id);
  }

  excluir(id: number) {
    this.service.excluir(id).subscribe(res => {
      this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    })
  }

}
