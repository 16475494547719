import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { AtributoProdutoDto } from 'src/app/shared/dto/AtributoProdutoDto';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { MovimentacaoHistoricoDto } from 'src/app/shared/dto/MovimentacaoHistoricoDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoAtributosDto } from 'src/app/shared/dto/ProdutoAtributosDto';
import { ProdutoDto } from 'src/app/shared/dto/ProdutoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ClienteService } from '../../cliente/cliente.service';
import { ParceriaService } from '../../parceria/parceria.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { UsuarioService } from '../../usuario/usuario.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class ProdutoFormularioComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: MovimentacaoHistoricoDto[] = [];

  id;
  produto: ProdutoDto;;
  loading = false;
  submitted = false;
  produtoTipos: ProdutoTipoDto[] = [];
  atributos: AtributoProdutoDto[] = [];
  locais: LocalDto[] = [{
    id: -1,
    nome: 'Entregador'
  },
  {
    id: 0,
    nome: 'Cliente'
  }];
  clientesFiltrados: ClienteDto[] = [];
  clientes: ClienteDto[] = [];
  entregadores: UsuarioDto[] = [];
  distribuidores: EmpresaDto[] = [];
  fornecedores: EmpresaDto[] = [];
  mostrarClientes: boolean;
  mostrarEntregador: boolean;
  mostrarDistribuidor: boolean;
  mostrarFornecedor: boolean;
  codEmpresa;

  form = new FormGroup({
    codigo: new FormControl('', [Validators.required]),
    tipo: new FormControl('', [Validators.required]),
    local: new FormControl('', [Validators.required]),
    entregador: new FormControl(''),
    cliente: new FormControl(''),
    distribuidor: new FormControl(''),
    fornecedor: new FormControl(''),
  });

  constructor(
    private service: ProdutoService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private produtoTipoService: ProdutoTipoService,
    private produtoLocalService: ProdutoLocalService,
    private clienteService: ClienteService,
    private usuarioService: UsuarioService,
    private parceriaService: ParceriaService,
    private movimentacaoHistoricoService: MovimentacaoHistoricoService,
    private tokenStorageService: TokenStorageService,
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
    this.codEmpresa = this.tokenStorageService.getDecodedToken().empresa;
    console.log(this.codEmpresa)
  }

  ngOnInit(): void {
    this.listaLocal();
    this.listaEntregadores();
    this.listaDistribuidores();
    this.listaFornecedores();

    if (this.id !== 'novo') {
      this.carregarDados();
      this.service.obterPorId(this.id).subscribe((res: ProdutoAtributosDto) => {
        this.id = res.produto.id;
        this.produto = res.produto;

        const localClienteOuEntregador = res.produto.clienteId ? 0 : -1;
        let local = res.produto.localId ? res.produto.localId : localClienteOuEntregador;

        const produtoDeFornecedor = res.produto.codigoEmpresa !== this.codEmpresa;
        const produtoComDistribuidor = res.produto.codigoEmpresaMovimentacao !== res.produto.codigoEmpresa;
        const produtoComigo = res.produto.codigoEmpresaMovimentacao === this.codEmpresa;
        if (produtoDeFornecedor) {
          this.form.controls['tipo'].disable();
        }
        this.form.controls['codigo'].disable();
        let distribuidor = '';
        let fornecedor = '';

        if (produtoDeFornecedor) {
          this.listaProdutoTipoFornecedor();
        } else {
          this.listaProdutoTipo();
        }

        if (produtoDeFornecedor && produtoComigo) {
          this.locais.push({
            id: -2,
            nome: 'Fornecedor'
          });
          fornecedor = res.produto.codigoEmpresa;
          this.form.controls['fornecedor'].disable();
        } else if (!produtoDeFornecedor && !produtoComDistribuidor) {
          this.locais.push({
            id: -3,
            nome: 'Distribuidor'
          });
        } else if (produtoDeFornecedor && !produtoComDistribuidor) {
          this.locais.push({
            id: -2,
            nome: 'Fornecedor'
          });
          local = -2;
          fornecedor = res.produto.codigoEmpresa;
          this.form.controls['fornecedor'].disable();
        } else if (produtoComDistribuidor && !produtoComigo) {
          this.locais.push({
            id: -3,
            nome: 'Distribuidor'
          });
          local = -3;
          distribuidor = res.produto.codigoEmpresaMovimentacao;
          this.form.controls['distribuidor'].disable();
        }

        setTimeout(() => {
          this.form.setValue({
            codigo: res.produto.codigo,
            tipo: res.produto.tipoId,
            local,
            cliente: null,
            entregador: res.produto.entregadorId,
            distribuidor,
            fornecedor,
          })
          this.listaCliente(res.produto.clienteId);

          this.alterarLocal(local);
          for (const atributo of res.atributos) {
            atributo.atributo.opcoesObj = this.produtoTipoService.opcoesParaObj(atributo.atributo.opcoes);
          }
          this.atributos = res.atributos;
        }, 500);

      }, error => {
        console.log(error);
      })
    } else {
      this.listaCliente();
      this.listaProdutoTipo();
    }
  }

  get f() {
    return this.form.controls;
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    let filtro: string = '';
    if (this.id && this.id !== '') {
      filtro = `produto:${this.id}`;
    }
    this.movimentacaoHistoricoService
      .paginaFiltrar(this.page - 1, this.pageSize, filtro)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  listaProdutoTipo() {
    this.produtoTipoService.lista().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  listaProdutoTipoFornecedor() {
    this.produtoTipoService.listaFornecedor().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
    }, error => {
      console.log(error);
    })
  }

  listaCliente(clienteId?) {
    this.clienteService.lista().subscribe((res: ClienteDto[]) => {
      this.clientes = res;
      if (clienteId) {
        const cliente = res.filter((cliente) => cliente.id === clienteId)[0];
        this.form.get('cliente').setValue(cliente);
      }
    }, error => {
      console.log(error);
    })
  }

  listaEntregadores() {
    this.usuarioService.listaEntregadores().subscribe((res: UsuarioDto[]) => {
      this.entregadores = res;
    }, error => {
      console.log(error);
    })
  }

  listaDistribuidores() {
    this.parceriaService.obterDistribuidores().subscribe((res: EmpresaDto[]) => {
      this.distribuidores = res;
    }, error => {
      console.log(error);
    })
  }

  listaFornecedores() {
    this.parceriaService.obterFornecedores().subscribe((res: EmpresaDto[]) => {
      this.fornecedores = res;
    }, error => {
      console.log(error);
    })
  }

  mudarProdutoTipo(id: number) {
    this.produtoTipoService.obterPorId(id).subscribe((res: ProdutoTipoDto) => {
      this.atributos = this.produtoTipoParaProdutoAtributo(res);
    }, error => {
      console.log(error);
    });
  }

  produtoTipoParaProdutoAtributo(produtoTipo: ProdutoTipoDto): AtributoProdutoDto[] {
    let produtoAtributos: AtributoProdutoDto[] = [];
    for (const atributo of produtoTipo.atributos) {
      atributo.opcoesObj = this.produtoTipoService.opcoesParaObj(atributo.opcoes);
      const produtoAtributo: AtributoProdutoDto = {
        atributo,
        valor: ''
      };
      produtoAtributos.push(produtoAtributo);
    }
    return produtoAtributos;
  }

  alterarLocal(id) {
    this.mostrarClientes = id === 0 || id === '0';
    this.mostrarEntregador = id === -1 || id === '-1';
    this.mostrarFornecedor = id === -2 || id === '-2';
    this.mostrarDistribuidor = id === -3 || id === '-3';
  }

  cadastrar() {
    this.submitted = true;
    this.form.markAllAsTouched();
    if (this.loading || this.form.invalid) {
      return;
    }

    const id = (this.id !== 'novo') ? this.id : null;

    const codigo: string = this.form.get('codigo').value;
    const tipoId: string = this.form.get('tipo').value;
    const localId: string = String(this.form.get('local').value);
    const clienteId: number = Number(this.form.get('cliente').value ? this.form.get('cliente').value.id : '');
    const entregadorId: string = this.form.get('entregador').value;
    const distribuidor: string = this.form.get('distribuidor').value;
    const fornecedor: string = this.form.get('fornecedor').value;

    if (this.mostrarClientes === true && clienteId === 0) {
      this.form.controls['cliente'].setErrors({ 'required': true });
      return;
    }

    if (this.mostrarEntregador === true && entregadorId === '') {
      this.form.controls['entregador'].setErrors({ 'required': true });
      return;
    }

    if (this.produto) {

      this.produto.codigoEmpresaMovimentacao = this.codEmpresa;
      if (this.mostrarDistribuidor === true) {
        this.produto.codigoEmpresaMovimentacao = distribuidor;
      } else if (this.mostrarFornecedor === true) {
        this.produto.codigoEmpresaMovimentacao = fornecedor;
      }

      this.produto.tipoId = Number(tipoId);
      this.produto.clienteId = (this.mostrarClientes === true && clienteId !== 0) ? Number(clienteId) : null;
      this.produto.entregadorId = (this.mostrarEntregador === true && entregadorId !== '') ? Number(entregadorId) : null;
      this.produto.localId = (this.mostrarEntregador === false && this.mostrarClientes === false) ? Number(localId) : null;

      this.form.get('codigo').setValue(this.produto.codigo);

    } else {
      this.produto = {
        codigo,
        tipoId: Number(tipoId),
        clienteId: (this.mostrarClientes === true && clienteId !== 0) ? Number(clienteId) : null,
        entregadorId: (this.mostrarEntregador === true && entregadorId !== '') ? Number(entregadorId) : null,
        localId: (this.mostrarEntregador === false && this.mostrarClientes === false) ? Number(localId) : null,
        codigoEmpresa: this.codEmpresa,
        codigoEmpresaMovimentacao: this.codEmpresa,
      }
    }

    const produtoAtributosDto: ProdutoAtributosDto = {
      produto: this.produto,
      atributos: this.atributos,
      dataHora: new Date()
    };

    this.loading = true;
    this.service.cadastrar(produtoAtributosDto).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
        this.router.navigateByUrl('/produto');
      },
      (err) => {
        this.loading = false;
        if (err.error.indexOf('EntityExistsException') != -1)
          this.utilService.mensagem(
            'Já existe outro produto com esse código!',
            UtilService.MENSAGEM_ERRO
          );
      }
    );
  }

  filterCliente(val) {
    let filter = this.clientes.filter((cliente) => cliente.nome.toLowerCase().indexOf(val.toLowerCase()) !== -1);
    this.clientesFiltrados = [...filter];
  }

  selectedclient(event) {
    console.log(event.option.value);
  }

  getOptionText(option) {
    if (option) {
      return option.nome;
    }
  }

  cancelar() {
    this.router.navigateByUrl('/produto');
  }

  gerarCodigo() {
    let random: string = Date.now().toString(36) + Math.random().toString(36);
    random = random.split('').sort(function () { return 0.5 - Math.random() }).join('').replace('.', '');
    this.form.get('codigo').setValue(random);
  }
}
