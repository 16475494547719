<div class="d-flex justify-content-between m-2">
    <h4>Tipo de Produto</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="editar('novo')"><i class="bi bi-plus-circle"></i>
    Novo</button>
</div>
<div class="formulario-box">
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col">Id</th>
                    <th scope="col" class="text-left">Nome</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let tipoProduto of tiposProduto">
                    <td scope="row">{{ tipoProduto.id }}</td>
                    <td scope="row" class="text-left">{{ tipoProduto.nome }}</td>
                    <td scope="row" class="acoes">
                        <button mat-icon-button class="btn-azul mr-2" type="button" (click)="editar(tipoProduto.id)"><i
                class="bi bi-pencil-square"></i></button>
                        <button mat-icon-button class="btn-warn" type="button" (click)="excluir(tipoProduto.id)"><i
                class="bi bi-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize" [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>