import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const TOKEN_KEY = 'auth-token';
const USERTOKEN_KEY = 'auth-usertoken';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(
    public router: Router, 
    ) { }

  isAuthenticated() {
    try {
      const decodedToken = this.getDecodedToken();
      const { exp } = decodedToken;
      if (exp < (new Date().getTime() + 1) / 1000) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveUserToken(userToken: any): void {
    window.sessionStorage.removeItem(USERTOKEN_KEY);
    window.sessionStorage.setItem(USERTOKEN_KEY, JSON.stringify(userToken));
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getUserToken(): any | null {
    return JSON.parse(window.sessionStorage.getItem(USERTOKEN_KEY));
  }

  public getDecodedToken() {
    return this.decodeToken(this.getToken());
  }

  decodeToken(token) {
    const _decodeToken = (token) => {
      try {
        return JSON.parse(atob(token));
      } catch {
        return;
      }
    };
    return token
      .split('.')
      .map(token => _decodeToken(token))
      .reduce((acc, curr) => {
        if (!!curr) acc = { ...acc, ...curr };
        return acc;
      }, Object.create(null));
  }
}
