import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProdutoListaComponent } from './lista/lista.component';
import { ProdutoFormularioComponent } from './formulario/formulario.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RelatorioModalComponent } from './relatorio-modal/relatorio-modal.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    ProdutoListaComponent,
    ProdutoFormularioComponent,
    RelatorioModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgbModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    QRCodeModule,
    MatSelectFilterModule,
    MatAutocompleteModule,
  ],
  exports: [
    ProdutoListaComponent,
    ProdutoFormularioComponent,
  ]
})
export class ProdutoModule { }
