import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { AtributoProdutoDto } from 'src/app/shared/dto/AtributoProdutoDto';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { MovimentacaoHistoricoDto } from 'src/app/shared/dto/MovimentacaoHistoricoDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoAtributosDto } from 'src/app/shared/dto/ProdutoAtributosDto';
import { ProdutoDto } from 'src/app/shared/dto/ProdutoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ClienteService } from '../../cliente/cliente.service';
import { ParceriaService } from '../../parceria/parceria.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { UsuarioService } from '../../usuario/usuario.service';
import { AtributoOpcaoDto, ProdutoTipoAtributoDto } from 'src/app/shared/dto/ProdutoTipoAtributoDto';
import { ProdutoService } from '../../produto/produto.service';

@Component({
  selector: 'app-movimentacao-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class MovimentacaoFormularioComponent implements OnInit {

  @ViewChild('codigoRef') meuInputRef!: ElementRef;
  selecionarLocal: boolean;
  selecionarProduto: boolean;
  confirmarMovimentacao: boolean;

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isEditable = false;
  lista: MovimentacaoHistoricoDto[] = [];

  id;
  responsavel;
  localLote: any;
  produtos: ProdutoAtributosDto[] = [];
  clientesFiltrados: ClienteDto[] = [];
  produto: ProdutoDto;;
  loading = false;
  submitted = false;
  produtoTipos: ProdutoTipoDto[] = [];
  atributos: AtributoProdutoDto[] = [];
  locais: LocalDto[] = [{
    id: -1,
    nome: 'Responsável'
  },
  {
    id: 0,
    nome: 'Cliente'
  }];
  produtosAtributosDto: ProdutoAtributosDto[];
  produtosForm: ProdutoLoteFormDto[] = [];
  codigo: string;
  clientes: ClienteDto[] = [];
  entregadores: UsuarioDto[] = [];
  distribuidores: EmpresaDto[] = [];
  fornecedores: EmpresaDto[] = [];
  mostrarClientes: boolean;
  mostrarEntregador: boolean;
  mostrarDistribuidor: boolean;
  mostrarFornecedor: boolean;
  codEmpresa;
  timer = null;

  form = new FormGroup({
    local: new FormControl('', [Validators.required]),
    entregador: new FormControl(''),
    cliente: new FormControl(''),
    distribuidor: new FormControl(''),
    fornecedor: new FormControl(''),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private produtoTipoService: ProdutoTipoService,
    private produtoLocalService: ProdutoLocalService,
    private clienteService: ClienteService,
    private usuarioService: UsuarioService,
    private parceriaService: ParceriaService,
    private produtoService: ProdutoService,
    private tokenStorageService: TokenStorageService,
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
    this.codEmpresa = this.tokenStorageService.getDecodedToken().empresa;

    this.selecionarLocal = true;
    this.selecionarProduto = false;
    this.confirmarMovimentacao = false;
  }

  ngOnInit(): void {
    this.listaLocal();
    this.listaCliente();
    this.listaEntregadores();
    this.listaDistribuidores();
    this.listaFornecedores();
    this.listaProdutoTipo();
  }

  get f() {
    return this.form.controls;
  }

  listaProdutoTipo() {
    this.produtoTipoService.obterProdutosTiposComAtributos().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  listaProdutoTipoFornecedor() {
    this.produtoTipoService.listaFornecedor().subscribe((res: ProdutoTipoDto[]) => {
      this.produtoTipos = res;
    }, error => {
      console.log(error);
    })
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
    }, error => {
      console.log(error);
    })
  }

  listaCliente() {
    this.clienteService.lista().subscribe((res: ClienteDto[]) => {
      this.clientes = res;
      this.clientesFiltrados = res;
    }, error => {
      console.log(error);
    })
  }

  listaEntregadores() {
    this.usuarioService.listaEntregadores().subscribe((res: UsuarioDto[]) => {
      this.entregadores = res;
    }, error => {
      console.log(error);
    })
  }

  listaDistribuidores() {
    this.parceriaService.obterDistribuidores().subscribe((res: EmpresaDto[]) => {
      this.distribuidores = res;
    }, error => {
      console.log(error);
    })
  }

  listaFornecedores() {
    this.parceriaService.obterFornecedores().subscribe((res: EmpresaDto[]) => {
      this.fornecedores = res;
    }, error => {
      console.log(error);
    })
  }

  mudarProdutoTipo(id: number) {
    this.produtoTipoService.obterPorId(id).subscribe((res: ProdutoTipoDto) => {
      this.atributos = this.produtoTipoParaProdutoAtributo(res);
    }, error => {
      console.log(error);
    });
  }

  produtoTipoParaProdutoAtributo(produtoTipo: ProdutoTipoDto): AtributoProdutoDto[] {
    let produtoAtributos: AtributoProdutoDto[] = [];
    for (const atributo of produtoTipo.atributos) {
      atributo.opcoesObj = this.produtoTipoService.opcoesParaObj(atributo.opcoes);
      const produtoAtributo: AtributoProdutoDto = {
        atributo,
        valor: ''
      };
      produtoAtributos.push(produtoAtributo);
    }
    return produtoAtributos;
  }

  salvarLocal() {
    this.submitted = true;
    this.form.markAllAsTouched();
    if (this.loading || this.form.invalid) {
      return;
    }

    const local = this.form.get('local').value;
    const cliente = this.form.get('cliente').value;
    const entregador = this.form.get('entregador').value;
    const distribuidor = this.form.get('distribuidor').value;
    const fornecedor = this.form.get('fornecedor').value;

    if (!local && local !== 0 && local !== '0') {
      this.utilService.mensagem('Selecione um local!', UtilService.MENSAGEM_ERRO);
      return;
    }

    if (this.mostrarClientes === true && !cliente) {
      this.utilService.mensagem('Selecione um cliente!', UtilService.MENSAGEM_ERRO);
      return;
    }

    if (this.mostrarEntregador === true && !entregador
      && (this.mostrarDistribuidor === false && this.mostrarFornecedor === false)) {
      this.utilService.mensagem('Selecione um entregador!', UtilService.MENSAGEM_ERRO);
      return;
    }

    if (this.mostrarDistribuidor === true && !distribuidor) {
      this.utilService.mensagem('Selecione um distribuidor!', UtilService.MENSAGEM_ERRO);
      return;
    }

    if (this.mostrarFornecedor === true && !fornecedor) {
      this.utilService.mensagem('Selecione um fornecedor!', UtilService.MENSAGEM_ERRO);
      return;
    }

    if (!local && !this.mostrarFornecedor && !this.mostrarDistribuidor && !this.mostrarEntregador && !this.mostrarClientes) {
      this.utilService.mensagem('Selecione um local!', UtilService.MENSAGEM_ERRO);
      return;
    }

    let codigoEmpresaMovimentacao = this.codEmpresa;
    if (this.mostrarDistribuidor === true) {
      codigoEmpresaMovimentacao = distribuidor;
    } else if (this.mostrarFornecedor === true) {
      codigoEmpresaMovimentacao = fornecedor;
    }

    this.localLote = {
      codigoEmpresaMovimentacao,
      clienteId: (this.mostrarClientes === true && cliente) ? Number(cliente.id) : null,
      entregadorId: (this.mostrarEntregador === true && entregador) ? Number(entregador) : null,
      localId: (this.mostrarEntregador === false && this.mostrarClientes === false) ? Number(local) : null,
    }

    this.selecionarLocal = false;
    this.selecionarProduto = true;
    this.focoNoCodigo();
  }

  obterProdutoTipoPorId(id: number): ProdutoTipoDto {
    const tipos = this.produtoTipos.filter(t => t.id === id);
    return tipos[0];
  }

  alterarLocal(id) {
    this.mostrarClientes = id === 0 || id === '0';
    this.mostrarEntregador = id === -1 || id === '-1';
    this.mostrarFornecedor = id === -2 || id === '-2';
    this.mostrarDistribuidor = id === -3 || id === '-3';
  }

  voltar() {
    if (this.selecionarProduto === true) {
      this.selecionarProduto = false;
      this.selecionarLocal = true;
    } else if (this.confirmarMovimentacao === true) {
      this.confirmarMovimentacao = false;
      this.selecionarProduto = true;
    }
  }

  filterCliente(val) {
    let filter = this.clientes.filter((cliente) => cliente.nome.toLowerCase().indexOf(val.toLowerCase()) !== -1);
    this.clientesFiltrados = [...filter];
  }

  selectedclient(event) {
    console.log(event.option.value);
  }

  getOptionText(option) {
    return option.nome;
  }

  cancelar() {
    this.router.navigateByUrl('/movimentacao');
  }

  buscarProdutoPorCodigo() {
    if (this.codigo) {
      const codigoCodificado = encodeURIComponent(this.codigo.toUpperCase());
      this.produtoService.obterPorCodigo(codigoCodificado).subscribe((produtoAtributos: ProdutoAtributosDto) => {
        if (produtoAtributos.possuiSolicitacao && produtoAtributos.possuiSolicitacao == true) {
          this.utilService.mensagem('O produto possui solicitação de movimentação pendente!', UtilService.MENSAGEM_ERRO);
        } else {
          let mensagem;
          const produtoDeFornecedor = produtoAtributos.produto.codigoEmpresa !== this.codEmpresa;
          const produtoComigo = produtoAtributos.produto.codigoEmpresaMovimentacao === this.codEmpresa;
          const produtoComDistribuidor = produtoAtributos.produto.codigoEmpresaMovimentacao !== produtoAtributos.produto.codigoEmpresa;

          if (this.localLote.clienteId && this.localLote.clienteId !== null
            && (this.localLote.clienteId === produtoAtributos.produto.clienteId)) {
            this.utilService.mensagem(`O produto ${produtoAtributos.produto.codigo} já está com o cliente!`, UtilService.MENSAGEM_ALERTA);
            return;
          }

          if (this.localLote.clienteId && this.localLote.clienteId !== null
            && produtoAtributos.produto.clienteId && produtoAtributos.produto.clienteId !== null
            && (this.localLote.clienteId !== produtoAtributos.produto.clienteId)) {
            this.utilService.mensagem(`O produto ${produtoAtributos.produto.codigo} está com outro cliente!`, UtilService.MENSAGEM_ALERTA);
            return;
          }

          if (produtoDeFornecedor && !produtoComigo) {
            this.utilService.mensagem(`O produto ${produtoAtributos.produto.codigo} não está com sua empresa!`, UtilService.MENSAGEM_ALERTA);
            return;
          }

          if (!produtoDeFornecedor && (this.localLote.codigoDistribuidor && this.localLote.codigoDistribuidor !== null
            && (this.localLote.codigoDistribuidor === produtoAtributos.produto.codigoEmpresaMovimentacao))) {
            mensagem = `O produto ${produtoAtributos.produto.codigo} já está com o distribuidor! Deseja realmente adicionar no lote?`;
          }

          this.confirmarAdicionarNoLote(produtoAtributos, mensagem);
        }
        this.codigo = '';
      }, error => {
        this.utilService.mensagem('Produto não encontrado!', UtilService.MENSAGEM_ERRO);
      })
    }
  }

  focoNoCodigo() {
    setTimeout(() => {
      this.meuInputRef.nativeElement.focus();
    }, 150);
  }

  async confirmarAdicionarNoLote(produtoAtributos: ProdutoAtributosDto, mensagem?: string) {
    if (!mensagem) {
      mensagem = `Adicionar produto ${produtoAtributos.produto.codigo} no lote?`;
    }
    this.utilService.mensagemConfirmacao(mensagem, UtilService.MENSAGEM_ALERTA)
      .then((result) => {
        if (result.isConfirmed) {
          this.adicionarProdutoNoLote(produtoAtributos);
        }
      });
    this.focoNoCodigo();
  }

  adicionarProdutoNoLote(produtoAtributos: ProdutoAtributosDto) {
    const produtoNaLista = this.produtos.filter(p => p.produto.codigo === produtoAtributos.produto.codigo);
    if (produtoNaLista.length > 0) {
      this.utilService.mensagem(`O produto já está no lote!`, UtilService.MENSAGEM_ALERTA);
    } else {
      produtoAtributos.atributos.sort((a, b) => a.atributo.nome.localeCompare(b.atributo.nome));
      this.produtos.push(produtoAtributos);
    }
  }

  async excluirDoLote(produtoId) {
    this.utilService.mensagemConfirmacao(`Deseja remover o produto do lote?`, UtilService.MENSAGEM_ALERTA)
      .then((result) => {
        if (result.isConfirmed) {
          this.produtos = this.produtos.filter(produtoAtributo => produtoAtributo.produto.id !== produtoId);
        }
      });
  }

  salvarProdutosLote() {
    if (this.produtos.length > 0) {
      this.produtosForm = [];
      for (const data of this.produtos) {
        const atributos: ProdutoTipoAtributoDto[] = data.atributos.map(atributosProduto => atributosProduto.atributo);
        atributos.forEach(atributo => atributo.opcoesObj = this.opcoesParaObj(atributo.opcoes));
        const form: ProdutoLoteFormDto = {
          tipo: data.produto.tipoId,
          atributos,
          editar: false
        };
        const tipoJaIncluido: ProdutoLoteFormDto[] = this.produtosForm.filter((p) => {
          return p.tipo === data.produto.tipoId;
        })
        if (tipoJaIncluido.length > 0) {
          tipoJaIncluido[0].codigos.push(data.produto.codigo)
        } else {
          form.codigos = [data.produto.codigo];
          this.produtosForm.push(form)
        }
      }
      this.selecionarProduto = false;
      this.confirmarMovimentacao = true;
    } else {
      this.utilService.mensagem(`Adicione algum produto no lote!`, UtilService.MENSAGEM_ALERTA);
    }
  }

  opcoesParaObj(opcoesStr: string): AtributoOpcaoDto[] {
    let opcoesObj: AtributoOpcaoDto[] = [];
    if (opcoesStr && opcoesStr !== '') {
      const opcoes: string[] = JSON.parse(opcoesStr);
      for (const opcao of opcoes) {
        opcoesObj.push({
          nome: opcao,
        });
      }
    }
    return opcoesObj;
  }

  editarAtributo(atributo: ProdutoTipoAtributoDto) {
    atributo.editar = atributo.editar ? false : true;
    if (atributo.editar === false) {
      atributo.valor = undefined;
    }
  }

  editarForm(form: ProdutoLoteFormDto) {
    form.editar = form.editar ? false : true;
  }

  voltarConfirmarMovimentacao() {
    this.confirmarMovimentacao = false;
    this.selecionarProduto = true;
  }

  voltarSelecionarProduto() {
    this.selecionarProduto = false;
    this.selecionarLocal = true;
  }

  salvarMovimentacao() {
    let produtosLote: ProdutoAtributosDto[] = [];

    if (!this.localLote.localId && !this.localLote.clienteId && !this.localLote.entregadorId) {
      this.utilService.mensagem(`Selecione o local novamente, se o problema persistir contate o suporte!`, UtilService.MENSAGEM_ALERTA);
      this.form.setValue({
        local: '',
        entregador: '',
        cliente: '',
        distribuidor: '',
        fornecedor: '',
      });
      this.confirmarMovimentacao = false;
      this.selecionarProduto = false;
      this.selecionarLocal = true;
      return;
    }

    for (const produtos of this.produtosForm) {
      let produtoDto: ProdutoDto = {
        codigo: produtos.codigos.toString().replace('[', '').replace(']', ''),
        tipoId: produtos.tipo,
        localId: this.localLote.localId,
        clienteId: this.localLote.clienteId,
        entregadorId: this.localLote.entregadorId,
        codigoEmpresaMovimentacao: this.localLote.codigoEmpresaMovimentacao,
      };

      let atributos: AtributoProdutoDto[] = [];
      produtos.atributos.forEach((item: ProdutoTipoAtributoDto) => {
        if (item.valor) {
          const atributo: AtributoProdutoDto = {
            id: item.id,
            valor: item.valor
          };
          atributos.push(atributo);
        }
      });

      const produtoAtributosDto: ProdutoAtributosDto = {
        produto: produtoDto,
        atributos,
        dataHora: new Date(),
        possuiSolicitacao: false
      };

      produtosLote.push(produtoAtributosDto);
    }

    this.produtoService.salvarLote(produtosLote).subscribe(res => {
      this.utilService.mensagem('Movimentanção feita com sucesso!', UtilService.MENSAGEM_SUCESSO);
      this.router.navigateByUrl('/movimentacao');
    }, error => {
      this.utilService.mensagem(this.utilService.traduzErro(error), UtilService.MENSAGEM_ERRO);
      if (error.error.message.indexOf('Selecione o local novamente') != -1) {
        this.form.setValue({
          local: '',
          entregador: '',
          cliente: '',
          distribuidor: '',
          fornecedor: '',
        });
        this.confirmarMovimentacao = false;
        this.selecionarProduto = false;
        this.selecionarLocal = true;
        return;
      }
    });
  }

  parouDeDigitar() {
    if (this.timer && this.timer !== null) {
      clearTimeout(this.timer);
    }
    if (this.codigo !== '') {
      this.timer = setTimeout(() => {
        this.buscarProdutoPorCodigo();
      }, 1000)
    }
  }
}

export interface ProdutoLoteFormDto {
  codigos?: string[];
  tipo?: number;
  atributos?: ProdutoTipoAtributoDto[];
  editar?: boolean;
}
