<div class="d-flex justify-content-between m-2">
    <h4>Relatório Totalizador de Produtos</h4>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Local</mat-label>
                <mat-select formControlName="local" (selectionChange)="alterarLocal($event.value)">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let local of locais" [value]="local.id"> {{local.nome}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarDistribuidor" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Distribuidor</mat-label>
                <mat-select formControlName="distribuidor">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let distribuidor of distribuidores" [value]="distribuidor.id">
                        {{distribuidor.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarClientes" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Cliente</mat-label>
                <input type="text" placeholder="Cliente" aria-label="Number" matInput formControlName="cliente"
                    [matAutocomplete]="auto" (keyup)="filterCliente($event.target.value)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                    <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente">
                        {{cliente.nome}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="f.cliente.hasError('required')"> Cliente é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarEntregador" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Entregador</mat-label>
                <mat-select formControlName="entregador">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let entregador of entregadores" [value]="entregador.id"> {{entregador.nome}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.entregador.hasError('required')"> Entregador é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let tipo of produtoTipos" [value]="tipo.id"> {{tipo.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.tipo.hasError('required')"> Tipo é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="imprimir()"><mat-icon>print</mat-icon>
            imprimir</button>
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="gerarExcel()"><mat-icon>print</mat-icon>
            Gerar Excel</button>
    </div>
</div>