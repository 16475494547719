import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { AlertaDto } from 'src/app/shared/dto/AlertaDto';
import { AtributoProdutoDto } from 'src/app/shared/dto/AtributoProdutoDto';
import { ClienteDto } from 'src/app/shared/dto/ClienteDto';
import { EmpresaDto } from 'src/app/shared/dto/EmpresaDto';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { MovimentacaoHistoricoDto } from 'src/app/shared/dto/MovimentacaoHistoricoDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ProdutoAtributosDto } from 'src/app/shared/dto/ProdutoAtributosDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ClienteService } from '../../cliente/cliente.service';
import { ParceriaService } from '../../parceria/parceria.service';
import { ProdutoLocalService } from '../../produto-local/produto-local.service';
import { ProdutoTipoService } from '../../produto-tipo/produto-tipo.service';
import { UsuarioService } from '../../usuario/usuario.service';
import { AlertaService } from '../alerta.service';

@Component({
  selector: 'app-alerta-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class AlertaFormularioComponent implements OnInit {

  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: MovimentacaoHistoricoDto[] = [];

  id;
  alerta: AlertaDto;
  loading = false;
  submitted = false;
  produtoTipos: ProdutoTipoDto[] = [];
  atributos: AtributoProdutoDto[] = [];
  locais: LocalDto[] = [{
    id: -1,
    nome: 'Entregador'
  },
  {
    id: 0,
    nome: 'Cliente'
  },
  {
    id: -3,
    nome: 'Distribuidor'
  }];
  clientes: ClienteDto[] = [];
  entregadores: UsuarioDto[] = [];
  distribuidores: EmpresaDto[] = [];
  fornecedores: EmpresaDto[] = [];
  mostrarClientes: boolean;
  mostrarEntregador: boolean;
  mostrarDistribuidor: boolean;
  mostrarFornecedor: boolean;
  codEmpresa;

  form = new FormGroup({
    descricao: new FormControl('', [Validators.required]),
    dias: new FormControl('', [Validators.required]),
    tipo: new FormControl('', [Validators.required]),
    local: new FormControl('', [Validators.required]),
    entregador: new FormControl(''),
    cliente: new FormControl(''),
    distribuidor: new FormControl(''),
  });

  constructor(
    private service: AlertaService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
    private produtoTipoService: ProdutoTipoService,
    private produtoLocalService: ProdutoLocalService,
    private clienteService: ClienteService,
    private usuarioService: UsuarioService,
    private parceriaService: ParceriaService,
    private movimentacaoHistoricoService: MovimentacaoHistoricoService,
    private tokenStorageService: TokenStorageService,
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
    this.codEmpresa = this.tokenStorageService.getDecodedToken().empresa;
    console.log(this.codEmpresa)
  }

  ngOnInit(): void {
    this.listaLocal();
    this.listaCliente();
    this.listaEntregadores();
    this.listaDistribuidores();

    if (this.id !== 'novo') {
      this.carregarDados();
      this.service.obterPorId(this.id).subscribe((res: AlertaDto) => {
        this.id = res.id;
        this.alerta = res;

        let local;

        console.log(res);

        // if (produtoDeFornecedor) {
        // } else {
        this.listaProdutoTipo();
        // }
        if (res.tipoLocal === 'local') {
          local = res.localId;
        } else if (res.tipoLocal === 'cliente') {
          local = 0;
        } else if (res.tipoLocal === 'entregador') {
          local = -1;
        } else if (res.tipoLocal === 'distribuidor') {
          local = -3;
        }

        setTimeout(() => {
          this.form.setValue({
            descricao: res.descricao,
            dias: res.dias,
            tipo: res.tipoId,
            local,
            entregador: res.responsavelId,
            cliente: res.clienteId,
            distribuidor: res.distribuidorId
          })
          this.alterarLocal(local);
        }, 500);

      }, error => {
        console.log(error);
      })
    } else {
      this.listaProdutoTipo();
    }
  }

  get f() {
    return this.form.controls;
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.movimentacaoHistoricoService
      .paginaFiltrar(this.page - 1, this.pageSize, this.id)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  async listaProdutoTipo() {
    const produtosTipos: any = await this.produtoTipoService.lista().toPromise();
    this.produtoTipos = produtosTipos;
    const produtosTiposFornecedor = await this.produtoTipoService.listaFornecedor().toPromise();
    this.produtoTipos = this.produtoTipos.concat(produtosTiposFornecedor);
  }

  listaLocal() {
    this.produtoLocalService.lista().subscribe((res: LocalDto[]) => {
      this.locais = this.locais.concat(res);
    }, error => {
      console.log(error);
    })
  }

  listaCliente() {
    this.clienteService.lista().subscribe((res: ClienteDto[]) => {
      this.clientes = res;
    }, error => {
      console.log(error);
    })
  }

  listaEntregadores() {
    this.usuarioService.listaEntregadores().subscribe((res: UsuarioDto[]) => {
      this.entregadores = res;
    }, error => {
      console.log(error);
    })
  }

  listaDistribuidores() {
    this.parceriaService.obterDistribuidores().subscribe((res: EmpresaDto[]) => {
      this.distribuidores = res;
    }, error => {
      console.log(error);
    })
  }

  alterarLocal(id) {
    this.mostrarClientes = id === 0 || id === '0';
    this.mostrarEntregador = id === -1 || id === '-1';
    this.mostrarDistribuidor = id === -3 || id === '-3';
  }

  cadastrar() {
    this.submitted = true;
    this.form.markAllAsTouched();
    if (this.loading || this.form.invalid) {
      return;
    }

    const id = (this.id !== 'novo') ? this.id : null;

    const descricao: string = this.form.get('descricao').value;
    const dias: number = Number(this.form.get('dias').value);
    const tipoId: number = Number(this.form.get('tipo').value);
    const localId: number = Number(this.form.get('local').value);
    const clienteId: number = Number(this.form.get('cliente').value);
    const entregadorId: number = Number(this.form.get('entregador').value);
    const distribuidorId: number = Number(this.form.get('distribuidor').value);
    let tipoLocal: string;

    if (localId !== 0 && localId !== -1 && localId !== -2 && localId !== -3 && localId !== -4) {
      tipoLocal = 'local';
    } else if (this.mostrarClientes === true) {
      tipoLocal = 'cliente';
    } else if (this.mostrarEntregador === true) {
      tipoLocal = 'entregador';
    } else if (this.mostrarDistribuidor === true) {
      tipoLocal = 'distribuidor';
    } else {
      tipoLocal = 'todos';
    }

    if (this.alerta) {
      Object.assign(this.alerta, {
        tipoLocal,
        descricao,
        dias,
        tipoId,
        localId: (localId === 0 || localId === -1 || localId === -2 || localId === -3) ? null : localId,
        clienteId: (this.mostrarClientes === false || clienteId === 0) ? null : clienteId,
        responsavelId: (this.mostrarEntregador === false || entregadorId === 0) ? null : entregadorId,
        distribuidorId: (this.mostrarDistribuidor === false || distribuidorId === 0) ? null : distribuidorId,
      });

    } else {
      this.alerta = {
        tipoLocal,
        descricao,
        dias,
        tipoId,
        localId: (localId === 0 || localId === -1 || localId === -2 || localId === -3) ? null : localId,
        clienteId: (this.mostrarClientes === false || clienteId === 0) ? null : clienteId,
        responsavelId: (this.mostrarEntregador === false || entregadorId === 0) ? null : entregadorId,
        distribuidorId: (this.mostrarDistribuidor === false || distribuidorId === 0) ? null : distribuidorId,
      }
    }

    this.loading = true;
    this.service.cadastrar(this.alerta).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
        this.router.navigateByUrl('/alerta');
      },
      (err) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(err),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }

  cancelar() {
    this.router.navigateByUrl('/alerta');
  }

}
