<div class="d-flex justify-content-between m-2">
    <h4>Produto</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i>
        Voltar</button>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-8">
            <div class="input-group">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Código</mat-label>
                    <input type="text" matInput formControlName="codigo">
                    <mat-error *ngIf="f.codigo.hasError('required')"> Código é <strong>obrigatório</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo" (selectionChange)="mudarProdutoTipo($event.value)">
                    <mat-option *ngFor="let tipo of produtoTipos" [value]="tipo.id"> {{tipo.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.tipo.hasError('required')"> Tipo é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Local</mat-label>
                <mat-select formControlName="local" (selectionChange)="alterarLocal($event.value)" required>
                    <mat-option *ngFor="let local of locais" [value]="local.id"> {{local.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.local.hasError('required')"> Altere o local </mat-error>
                <mat-hint>{{f.local.value?.id}}</mat-hint>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarDistribuidor" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Distribuidor</mat-label>
                <mat-select formControlName="distribuidor">
                    <mat-option *ngFor="let distribuidor of distribuidores" [value]="distribuidor.codigo">
                        {{distribuidor.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarFornecedor" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Fornecedor</mat-label>
                <mat-select formControlName="fornecedor">
                    <mat-option *ngFor="let fornecedor of fornecedores" [value]="fornecedor.codigo"> {{fornecedor.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarClientes" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Cliente</mat-label>
                <input type="text" placeholder="Cliente" aria-label="Number" matInput formControlName="cliente"
                    [matAutocomplete]="auto" (keyup)="filterCliente($event.target.value)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                    <mat-option *ngFor="let cliente of clientesFiltrados" [value]="cliente">
                        {{cliente.nome}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="f.cliente.hasError('required')"> Cliente é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarEntregador" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Entregador</mat-label>
                <mat-select formControlName="entregador">
                    <mat-option *ngFor="let entregador of entregadores" [value]="entregador.id"> {{entregador.nome}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.entregador.hasError('required')"> Entregador é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="row">
        <ng-container *ngFor="let atributo of atributos; let i = index">
            <div class="form-group col-xs-12 col-sm-4">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>{{atributo.atributo.nome}}</mat-label>
                    <mat-select *ngIf="atributo.atributo.tipo === 'opcoes'" [(ngModel)]="atributo.valor">
                        <mat-option *ngFor="let opcao of atributo.atributo.opcoesObj" [value]="opcao.nome">
                            {{opcao.nome}} </mat-option>
                    </mat-select>
                    <input *ngIf="atributo.atributo.tipo !== 'opcoes'" type="text" matInput [(ngModel)]="atributo.valor"
                        name="valor">
                </mat-form-field>

            </div>
        </ng-container>
    </div>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="cadastrar()">Salvar</button>
        <button mat-raised-button color="warn" type="button" (click)="cancelar()">Cancelar</button>
    </div>
</div>
<div *ngIf="id !== 'novo'" class="formulario-box mt-3">
    <h5 class="mb-3">Histórico de movimentações</h5>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr class="text-center">
                    <th scope="col">Data</th>
                    <th scope="col">Usuário</th>
                    <th scope="col" class="text-left">Movimentação</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row">{{ item.nomeUsuario }}</td>
                    <td scope="row" class="text-left">{{ item.descricao }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize"
        [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>