import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/usuario`;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private http: HttpClient
  ) { }

  cadastrar(UsuarioDto: UsuarioDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, UsuarioDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number, id: number, nome: string): Observable<Object> {
    const filtroId = id ? `&id=${id}` : `&id=`;
    const filtroNome = nome ? `&nome=${nome}` : `&nome=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtroId}${filtroNome}`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/${id}`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }

  lista() {
    return this.http.get(`${API}/lista`);
  }

  listaEntregadores() {
    return this.http.get(`${API}/lista`);
  }

  listaEmpresas(): Observable<any> {
    return this.http.get(`${API}/lista-empresas`);
  }

}
