<div class="d-flex justify-content-between m-2">
    <h4>Produtos</h4>
    <div>
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="editar('novo')"><i
                class="bi bi-plus-circle"></i>
            Novo</button>
        <button mat-raised-button class="btn-azul" type="button" (click)="relatorioModal()"><mat-icon>print</mat-icon>
            Relatório Totalizador</button>
    </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
        <a ngbNavLink>Meus</a>
        <ng-template ngbNavContent>
            <div class="formulario-box">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Código</mat-label>
                            <input type="text" matInput [(ngModel)]="codigo" name="codigo"
                                (ngModelChange)="codigo = $event.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-3 form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Tipo</mat-label>
                            <mat-select [(ngModel)]="tipoId">
                                <mat-option *ngFor="let tipo of produtoTipos" [value]="tipo.id"> {{tipo.nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="btn-mob">
                        <button mat-raised-button class="btn-azul" type="button" (click)="carregarDados()"><i
                                class="bi bi-search"></i></button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover" id="job-table">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">Id</th>
                                <th scope="col" class="text-left">Código</th>
                                <th scope="col" class="text-left">Tipo</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr *ngFor="let item of lista">
                                <td scope="row">{{ item.id }}</td>
                                <td scope="row" class="text-left">{{ item.codigo }}
                                    <mat-icon *ngIf="obterAlerta(item.dias,
                                    item.diasrestantes)" [ngStyle]="{'color': obterAlerta(item.dias,
                                    item.diasrestantes)}">report</mat-icon>
                                </td>
                                <td scope="row" class="text-left">{{ item.tipo }}
                                </td>
                                <td scope="row" class="acoes">
                                    <button mat-icon-button class="btn-azul mr-2" type="button"
                                        (click)="editar(item.id)"><i class="bi bi-pencil-square"></i></button>
                                    <button mat-icon-button class="btn-warn" type="button" (click)="excluir(item.id)"><i
                                            class="bi bi-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page"
                    [maxSize]="pageSize" [rotate]="true" (pageChange)="carregarPagina($event)">
                </ngb-pagination>
            </div>
        </ng-template>
    </li>
    <li [ngbNavItem]="2">
        <a ngbNavLink>Fornecedores</a>
        <ng-template ngbNavContent>
            <div class="formulario-box">
                <div class="row">
                    <div class="col-4 form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Código</mat-label>
                            <input type="text" matInput [(ngModel)]="codigoFornecedor" name="codigoFornecedor"
                                (ngModelChange)="codigoFornecedor = $event.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="col-3 form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Tipo</mat-label>
                            <mat-select [(ngModel)]="tipoIdFornecedor">
                                <mat-option *ngFor="let tipo of produtoTiposFornecedor" [value]="tipo.id"> {{tipo.nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-raised-button class="btn-azul" type="button" (click)="carregarDadosFornecedor()"><i
                                class="bi bi-search"></i></button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover" id="job-table">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">Id</th>
                                <th scope="col" class="text-left">Código</th>
                                <th scope="col" class="text-left">Tipo</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody class="text-center">
                            <tr *ngFor="let item of listaFornecedor">
                                <td scope="row">{{ item.id }}</td>
                                <td scope="row" class="text-left">
                                    {{ item.codigo }}
                                    <mat-icon *ngIf="obterAlerta(item.dias,
                                    item.diasrestantes)" [ngStyle]="{'color': obterAlerta(item.dias,
                                    item.diasrestantes)}">report</mat-icon>
                                </td>
                                <td scope="row" class="text-left">{{ item.tipo }}</td>
                                <td scope="row" class="acoes">
                                    <button mat-icon-button class="btn-azul" type="button" (click)="editar(item.id)"><i
                                            class="bi bi-pencil-square"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ngb-pagination [collectionSize]="collectionSizeFornecedor" [pageSize]="pageSizeFornecedor"
                    [(page)]="pageFornecedor" [maxSize]="pageSizeFornecedor" [rotate]="true"
                    (pageChange)="carregarPaginaFornecedor($event)">
                </ngb-pagination>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>