<div class="d-flex justify-content-between m-2">
    <h4>Visão Geral</h4>
</div>
<div class="alertas mb-3">
    <div class="row">
        <div class="col-4">
            <div class="emdia">
                <div>
                    <div class="titulo">EM DIA</div>
                    <div>{{qtdEmdia}} produtos estão em dia.</div>
                </div>
                <div class="d-flex">
                    <button mat-stroked-button (click)="relatorioAlertaModal('emdia')"><mat-icon>print</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="avencer">
                <div>
                    <div class="titulo">A VENCER</div>
                    <div>{{qtdAvencer}} produtos estão próximos do vencimento.</div>
                </div>
                <div class="d-flex">
                    <button mat-stroked-button
                        (click)="relatorioAlertaModal('avencer')"><mat-icon>print</mat-icon></button>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="ociosos">
                <div>
                    <div class="titulo">OCIOSOS</div>
                    <div>{{qtdOciosos}} produtos estão ociosos.</div>
                </div>
                <div class="d-flex">
                    <button mat-stroked-button
                        (click)="relatorioAlertaModal('ociosos')"><mat-icon>print</mat-icon></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="formulario-box mb-3" *ngIf="listaSolicitacoes.length > 0">
    <h5 class="mb-3">Solicitações de movimentações recebidas</h5>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Data</th>
                    <th scope="col" class="text-left">Usuário</th>
                    <th scope="col" class="text-left">Solicitante</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of listaSolicitacoes">
                    <td scope="row" class="text-left">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row" class="text-left">{{ item.usuario }}</td>
                    <td scope="row" class="text-left">{{ item.requerente }}</td>
                    <td scope="row">
                        <button mat-icon-button class="btn-azul mr-2" type="button"
                            (click)="visualizarSolicitacao(item.id)"><i class="bi bi-eye"></i></button>
                        <button mat-icon-button class="btn-success mr-2" type="button"
                            (click)="confirmarSolicitacao(item)"><i class="bi bi-check-circle"></i></button>
                        <button mat-icon-button class="btn-warn" type="button" (click)="recusarSolicitacao(item.id)"><i
                                class="bi bi-x-circle"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="formulario-box mb-3" *ngIf="listaSolicitacoesFeitas.length > 0">
    <h5 class="mb-3">Solicitações de movimentações feitas</h5>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Data</th>
                    <th scope="col" class="text-left">Usuário</th>
                    <th scope="col" class="text-left">Destino</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of listaSolicitacoesFeitas">
                    <td scope="row" class="text-left">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row" class="text-left">{{ item.usuario }}</td>
                    <td scope="row" class="text-left">{{ item.empresa }}</td>
                    <td scope="row">
                        <button mat-icon-button class="btn-azul mr-2" type="button"
                            (click)="visualizarSolicitacao(item.id)"><i class="bi bi-eye"></i></button>
                        <button mat-icon-button class="btn-warn" type="button" (click)="recusarSolicitacao(item.id)"><i
                                class="bi bi-x-circle"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="formulario-box mt-3">
    <h5>Totalizador de produtos por localização</h5>
    <ngx-charts-advanced-pie-chart (window:resize)="onResize($event)" [view]="view" [scheme]="colorScheme"
        [results]="graficoProdutosLocais" [percentageFormatting]="percentageFormatting" [gradient]="gradient"
        (select)="onSelect($event)">
    </ngx-charts-advanced-pie-chart>
    <!-- <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Local</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of listaTotalizadorLocalizacao">
                    <td scope="row" class="text-left">{{ item.local }}: {{item.nome}}</td>
                    <td scope="row">{{ item.tipoProduto }}</td>
                    <td scope="row">{{ item.quantidade }}</td>
                    <td scope="row">
                        <button class="btn btn-primary mr-2" (click)="visualizarDetalhesProdutosLocal(item.id, item.local)"><i
                class="bi bi-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSizeTotalizadorLocalizacao" [pageSize]="pageSizeTotalizadorLocalizacao" [(page)]="pageTotalizadorLocalizacao" [maxSize]="pageSizeTotalizadorLocalizacao" [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination> -->
</div>

<div class="formulario-box mt-3">
    <h5 class="mb-3">Localização</h5>
    <div id="mapa">
    </div>
    <div id="popup" class="ol-popup">
        <a href="#" id="popup-closer" class="ol-popup-closer"></a>
        <div id="popup-content"></div>
        <button mat-raised-button class="popup-button" (click)="abrirGoogleMaps()">Google Maps <i
                class="bi bi-map"></i></button>
    </div>
</div>

<div class="formulario-box mt-3">
    <h5 class="mb-3">Últimas movimentações</h5>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
                <tr class="text-center">
                    <th scope="col">Data</th>
                    <th scope="col">Usuário</th>
                    <th scope="col" class="text-left">Movimentação</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row">{{ item.dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td scope="row">{{ item.nomeUsuario }}</td>
                    <td scope="row" class="text-left">{{ item.descricao }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize"
        [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>