import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/auth/token-storage.service';
import { UsuarioDto } from 'src/app/shared/dto/UsuarioDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-usuario-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class UsuarioFormularioComponent implements OnInit {

  id;
  loading = false;
  submitted = false;

  tipos: any[] = [
    {
      nome: 'Administrador',
      value: 'administrador'
    },
    {
      nome: 'Encarregado',
      value: 'encarregado'
    },
    {
      nome: 'Entregador',
      value: 'entregador'
    }
  ]

  form = new FormGroup({
    codigo: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    nome: new FormControl('', [Validators.required]),
    tipo: new FormControl('', [Validators.required]),
    senha: new FormControl('', [Validators.required]),
  });

  role: string;

  constructor(
    private tokenStorageService: TokenStorageService,
    private service: UsuarioService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe(params => this.id = params['id']);
    const decodedToken = this.tokenStorageService.getDecodedToken();
    this.role = decodedToken.role;
  }

  ngOnInit(): void {
    if (this.id !== 'novo') {
      this.service.obterPorId(this.id).subscribe((res: UsuarioDto) => {
        this.id = res.id;
        this.form.setValue({
          codigo: res.codigo,
          email: res.email,
          nome: res.nome,
          tipo: res.tipo,
          senha: ''
        });
      }, error => {
        console.log(error);
      })
    }
  }

  get f() {
    return this.form.controls;
  }

  cadastrar() {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.loading || this.form.invalid) {
      return;
    }

    const usuario: UsuarioDto = this.form.getRawValue();
    usuario.id = (this.id !== 'novo') ? this.id : null;

    this.loading = true;
    console.log(usuario)
    this.service.cadastrar(usuario).subscribe(
      (res: any) => {
        this.loading = false;
        this.utilService.mensagem('Salvo com sucesso!', UtilService.MENSAGEM_SUCESSO)
        this.router.navigateByUrl('/usuario');
      },
      (error) => {
        this.loading = false;
        this.utilService.mensagem(
          this.utilService.traduzErro(error),
          UtilService.MENSAGEM_ERRO
        );
      }
    );
  }

  cancelar() {
    this.router.navigateByUrl('/usuario');
  }
}
