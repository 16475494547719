import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisaoGeralComponent } from './visao-geral.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmarSolicitacaoComponent } from './confimar-solicitacao/confirmar-solicitacao.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetalhesSolicitacaoComponent } from './detalhes-solicitacao/detalhes-solicitacao.component';
import { DetalhesProdutoLocalComponent } from './detalhes-produtos-local/detalhes-produtos-local.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { RelatorioAlertaModalComponent } from './relatorio-alerta-modal/relatorio-alerta-modal.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    VisaoGeralComponent,
    ConfirmarSolicitacaoComponent,
    DetalhesSolicitacaoComponent,
    DetalhesProdutoLocalComponent,
    RelatorioAlertaModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,
    MatCardModule,
    MatSelectFilterModule,
    MatAutocompleteModule,
  ],
  exports: [VisaoGeralComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VisaoGeralModule { }
