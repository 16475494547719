<div class="d-flex justify-content-between m-2">
    <h4>Local</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i>
        Voltar</button>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-12">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Nome</mat-label>
                <input type="text" matInput formControlName="nome">
                <mat-error *ngIf="f.nome.hasError('required')"> Nome é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="cadastrar()">Salvar</button>
        <button mat-raised-button color="warn" type="button" (click)="cancelar()">Cancelar</button>
    </div>
</div>

<div class="formulario-box mt-3 mb-3">
    <h5>Produtos no local</h5>
    <div class="table-responsive">
        <table class="table table-hover" id="job-table">
            <thead>
                <tr class="text-center">
                    <th scope="col" class="text-left">Detalhes</th>
                    <th scope="col" class="text-center">Produtos</th>
                    <th scope="col" class="text-center">Quantidade</th>
                </tr>
            </thead>
            <tbody class="text-center">
                <tr *ngFor="let item of lista">
                    <td scope="row" class="text-left">{{ item.detalhes }}</td>
                    <td scope="row" class="text-left">{{ item.codigos }}</td>
                    <td scope="row" class="text-center">{{ item.quantidade }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ngb-pagination [collectionSize]="collectionSize" [pageSize]="pageSize" [(page)]="page" [maxSize]="pageSize"
        [rotate]="true" (pageChange)="carregarPagina($event)">
    </ngb-pagination>
</div>

<div class="formulario-box mt-3 mb-3">
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Buscar por endereço</mat-label>
        <input type="text" [(ngModel)]="enderecoPesquisa" matInput>
        <button mat-icon-button matSuffix (click)="pesquisarEndereco()" [attr.aria-label]="'Buscar por endereço'">
            <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
    <h5>Localização</h5>
    <div id="mapa">
    </div>
</div>