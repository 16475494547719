import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalDto } from 'src/app/shared/dto/LocalDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/local`;

@Injectable({
  providedIn: 'root'
})
export class ProdutoLocalService {

  constructor(
    private http: HttpClient
  ) { }

  cadastrar(localDto: LocalDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, localDto, options);
  }

  salvarCoordenada(geometriaDto: any): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar-coordenada`, geometriaDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number, id: number, nome: string): Observable<Object> {
    const filtroId = id ? `&id=${id}` : `&id=`;
    const filtroNome = nome ? `&nome=${nome}` : `&nome=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtroId}${filtroNome}`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/${id}`);
  }

  lista() {
    return this.http.get(`${API}/lista`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }
}
