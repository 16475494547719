<div class="d-flex justify-content-between m-2">
    <h4>Alerta</h4>
    <button mat-raised-button class="btn-azul" type="button" (click)="cancelar()"><i class="bi bi-arrow-left"></i>
        Voltar</button>
</div>
<div class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-xs-12 col-sm-8">
            <div class="input-group">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Descrição</mat-label>
                    <input type="text" matInput formControlName="descricao">
                    <mat-error *ngIf="f.descricao.hasError('required')"> Descrição é <strong>obrigatória</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <div class="input-group">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Quantidade de dias</mat-label>
                    <input type="text" matInput formControlName="dias">
                    <mat-error *ngIf="f.dias.hasError('required')"> Quantidade de dias é <strong>obrigatório</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option *ngFor="let tipo of produtoTipos" [value]="tipo.id"> {{tipo.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.tipo.hasError('required')"> Tipo é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Local</mat-label>
                <mat-select formControlName="local" (selectionChange)="alterarLocal($event.value)" required>
                    <mat-option value="">Todos</mat-option>
                    <mat-option *ngFor="let local of locais" [value]="local.id"> {{local.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.local.hasError('required')"> Altere o local </mat-error>
                <mat-hint>{{f.local.value?.id}}</mat-hint>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarDistribuidor" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Distribuidor</mat-label>
                <mat-select formControlName="distribuidor">
                    <mat-option value="0">Todos</mat-option>
                    <mat-option *ngFor="let distribuidor of distribuidores" [value]="distribuidor.id">
                        {{distribuidor.nome}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarClientes" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Cliente</mat-label>
                <mat-select formControlName="cliente">
                    <mat-option value="0">Todos</mat-option>
                    <mat-option *ngFor="let cliente of clientes" [value]="cliente.id"> {{cliente.nome}} </mat-option>
                </mat-select>
                <mat-error *ngIf="f.cliente.hasError('required')"> Cliente é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="mostrarEntregador" class="form-group col-xs-12 col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Entregador</mat-label>
                <mat-select formControlName="entregador">
                    <mat-option value="0">Todos</mat-option>
                    <mat-option *ngFor="let entregador of entregadores" [value]="entregador.id"> {{entregador.nome}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.entregador.hasError('required')"> Entregador é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="cadastrar()">Salvar</button>
        <button mat-raised-button color="warn" type="button" (click)="cancelar()">Cancelar</button>
    </div>
</div>