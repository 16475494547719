<div class="painel" [class.is-mobile]="mobileQuery.matches">
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sidenav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches">
            <app-menu-lateral></app-menu-lateral>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-cabecalho (sidenav)="sidenav.toggle()"></app-cabecalho>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
