import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { ParceriaTokenDto } from 'src/app/shared/dto/ParceriaTokenDto';
import { UtilService } from 'src/app/shared/service/util.service';
import { ParceriaTokenService } from '../parceria-token.service';
import { ParceriaService } from '../parceria.service';

@Component({
  selector: 'app-gerar-token',
  templateUrl: './gerar-token.component.html',
  styleUrls: ['./gerar-token.component.scss']
})
export class GerarTokenComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  pageSize: number = 5;
  collectionSize: number;
  page: number = 0;
  previousPage: number;
  lista: ParceriaTokenDto[] = [];

  constructor(
    public modal: NgbActiveModal,
    private service: ParceriaTokenService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPagina(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.carregarDados();
    }
  }

  carregarDados() {
    this.service.paginaFiltrar(this.page - 1, this.pageSize)
      .subscribe((response: PaginaDto) => {
        this.lista = response.content;
        this.collectionSize = response.totalElements;
      });
  }

  gerar() {
    this.service.gerar().subscribe(data => {
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    });
  }

  excluir(id: number) {
    this.service.excluir(id).subscribe(res => {
      this.utilService.mensagem('Excluído com sucesso!', UtilService.MENSAGEM_SUCESSO)
      this.carregarDados();
    }, error => {
      this.utilService.mensagem(
        this.utilService.traduzErro(error),
        UtilService.MENSAGEM_ERRO
      );
    })
  }

}
