<div *ngIf="role === 'administrador'" class="d-flex justify-content-between m-2">
    <h4>Empresa</h4>
</div>
<div *ngIf="role === 'administrador'" class="formulario-box">
    <form [formGroup]="form" class="row">
        <div class="form-group col-6">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Nome</mat-label>
                <input type="text" matInput formControlName="nome">
                <mat-error *ngIf="f.nome.hasError('required')"> Nome é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group col-3">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Cnpj</mat-label>
                <input type="cnpj" matInput formControlName="cnpj">
            </mat-form-field>
        </div>
        <div class="form-group col-3">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Telefone</mat-label>
                <input type="text" matInput formControlName="telefone">
            </mat-form-field>
        </div>
        <div class="form-group col-7">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Endereço</mat-label>
                <input type="text" matInput formControlName="endereco">
            </mat-form-field>
        </div>
        <div class="form-group col-5">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Imagem</mat-label>
                <input type="file" #fileInput formControlName="imagem" (change)="onFileSelected($event)"
                    style="opacity: 0; position: absolute;">
                <mat-icon matSuffix (click)="fileInput.click()">attach_file</mat-icon>
                <input matInput readonly>
                <div *ngIf="imagemPreview">
                    <img [src]="imagemPreview" style="max-width: 400px;">
                </div>
                <mat-error *ngIf="form.get('imagem').invalid && form.get('imagem').touched">Imagem inválida</mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="rodape-form">
        <button mat-raised-button class="btn-azul mr-2" type="button" (click)="cadastrar()">Salvar</button>
    </div>
</div>