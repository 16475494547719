import { Injectable } from '@angular/core';
import { AutenticarDto } from '../shared/dto/AutenticarDto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../shared/service/util.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(autenticarDto: AutenticarDto) {
    return this.http.post<AutenticarDto>(
      UtilService.API + '/autenticacao/',
      autenticarDto
    );
  }

  useLoginToken(token: string, codigoEmpresa: string) {
    const tokenDto = {
      token,
      codigoEmpresa,
    };
    return this.http.post<AutenticarDto>(UtilService.API + '/autenticacao/token', tokenDto);
  }
}
