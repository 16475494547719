import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MovimentacaoHistoricoDto } from 'src/app/shared/dto/MovimentacaoHistoricoDto';
import { MovimentacaoSolicitacaoDto } from 'src/app/shared/dto/MovimentacaoSolicitacaoDto';
import { PaginaDto } from 'src/app/shared/dto/PaginaDto';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { MovimentacaoSolicitacaoService } from 'src/app/shared/service/movimentacao-solicitacao.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { ConfirmarSolicitacaoComponent } from '../visao-geral/confimar-solicitacao/confirmar-solicitacao.component';
import { DetalhesProdutoLocalComponent } from '../visao-geral/detalhes-produtos-local/detalhes-produtos-local.component';
import { DetalhesSolicitacaoComponent } from '../visao-geral/detalhes-solicitacao/detalhes-solicitacao.component';
import { VisaoGeralService } from '../visao-geral/visao-geral.service';

@Component({
  selector: 'app-historico-solicitacao',
  templateUrl: './historico-solicitacao.component.html',
  styleUrls: ['./historico-solicitacao.component.scss']
})
export class HistoricoSolicitacaoComponent implements OnInit {

  pageSizeFeitas: number = 5;
  collectionSizeFeitas: number;
  pageFeitas: number = 0;
  previousPageFeitas: number;
  listaFeitas: MovimentacaoSolicitacaoDto[] = [];

  pageSizeRecebidas: number = 5;
  collectionSizeRecebidas: number;
  pageRecebidas: number = 0;
  previousPageRecebidas: number;
  listaRecebidas: MovimentacaoSolicitacaoDto[] = [];

  graficoProdutosLocais = [];

  view: any[] = [1000, 300];

  gradient: boolean = true;
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(
    private service: VisaoGeralService,
    private modalService: NgbModal,
    private movimentacaoHistoricoService: MovimentacaoHistoricoService,
    private movimentacaoSolicitacaoService: MovimentacaoSolicitacaoService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.carregarDados();
  }

  carregarPaginaRecebidas(page: number) {
    if (page !== this.previousPageRecebidas) {
      this.previousPageRecebidas = page;
    }
    this.movimentacaoSolicitacaoService
      .todas(this.pageRecebidas - 1, this.pageSizeRecebidas)
      .subscribe((response: PaginaDto) => {
        this.listaRecebidas = response.content;
        console.log(response.content)
        this.collectionSizeRecebidas = response.totalElements;
      });
  }

  carregarPaginaFeitas(page: number) {
    if (page !== this.previousPageFeitas) {
      this.previousPageFeitas = page;
    }
    this.movimentacaoSolicitacaoService
      .todasFeitas(this.pageFeitas - 1, this.pageSizeFeitas)
      .subscribe((response: PaginaDto) => {
        console.log(response.content)
        this.listaFeitas = response.content;
        this.collectionSizeFeitas = response.totalElements;
      });
  }

  carregarDados() {
    this.carregarPaginaRecebidas(this.pageRecebidas);
    this.carregarPaginaFeitas(this.pageFeitas);
  }

  visualizarSolicitacao(id: number) {
    const modalOpcoes = this.modalService.open(DetalhesSolicitacaoComponent, { size: 'md' });
    modalOpcoes.componentInstance.id = id;
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
    });
  }

  confirmarSolicitacao(solicitacao: any) {
    const modalOpcoes = this.modalService.open(ConfirmarSolicitacaoComponent, { size: 'md' });
    modalOpcoes.componentInstance.solicitacao = solicitacao;
    modalOpcoes.componentInstance.passEntry.subscribe((retorno: any) => {
      this.carregarDados();
    });
  }

  recusarSolicitacao(id: number) {
    const dto = {
      id: id,
      confirmar: false,
      localId: null
    }
    this.utilService.mensagemConfirmacao(`Deseja recusar a solicitação?`, UtilService.MENSAGEM_ALERTA)
      .then((result) => {
        if (result.isConfirmed) {
          this.movimentacaoSolicitacaoService.confirmarSolicitacao(dto).subscribe(res => {
            this.utilService.mensagem(`Solicitação recusada!`, UtilService.MENSAGEM_SUCESSO);
            this.carregarDados();
          }, error => {
            this.utilService.mensagem(
              this.utilService.traduzErro(error),
              UtilService.MENSAGEM_ERRO
            );
          });
        }
      });
  }
}
