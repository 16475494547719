import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParceriaComponent } from './parceria.component';
import { GerarTokenComponent } from './gerar-token/gerar-token.component';
import { AdicionarTokenComponent } from './adicionar-token/adicionar-token.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalizacaoComponent } from './localizacao/localizacao.component';



@NgModule({
  declarations: [ParceriaComponent, AdicionarTokenComponent, GerarTokenComponent, LocalizacaoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
  ],
  exports: [ParceriaComponent]
})
export class ParceriaModule { }
