import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AtributoOpcaoDto, ProdutoTipoAtributoDto } from 'src/app/shared/dto/ProdutoTipoAtributoDto';
import { ProdutoTipoDto } from 'src/app/shared/dto/ProdutoTipoDto';
import { UtilService } from 'src/app/shared/service/util.service';

const API = `${UtilService.API}/produto-tipo`;

@Injectable({
  providedIn: 'root'
})
export class ProdutoTipoService {


  constructor(
    private http: HttpClient
  ) { }

  cadastrar(produtoTipoDto: ProdutoTipoDto): any {
    const options = { responseType: 'text' as 'json' };
    return this.http.post(`${API}/salvar`, produtoTipoDto, options);
  }

  paginaFiltrar(pagina: number, tamanho: number, id: number, nome: string): Observable<Object> {
    const filtroId = id ? `&id=${id}` : `&id=`;
    const filtroNome = nome ? `&nome=${nome}` : `&nome=`;
    return this.http.get(`${API}/pagina-filtrar?page=${pagina}&size=${tamanho}&sort=id,desc${filtroId}${filtroNome}`);
  }

  obterPorId(id: number) {
    return this.http.get(`${API}/${id}`);
  }

  lista() {
    return this.http.get(`${API}/lista`);
  }

  listaFornecedor() {
    return this.http.get(`${API}/lista-fornecedor`);
  }

  excluir(id: number) {
    return this.http.delete(`${API}/${id}`);
  }

  obterProdutosTiposComAtributos() {
    return this.http.get(`${API}/obter-produtos-tipos-com-atributos`);
  }

  opcoesParaObj(opcoesStr: string): AtributoOpcaoDto[] {
    let opcoesObj: AtributoOpcaoDto[] = [];
    if (opcoesStr && opcoesStr !== '') {
      const opcoes: string[] = JSON.parse(opcoesStr);
      for (const opcao of opcoes) {
        opcoesObj.push({
          nome: opcao,
        });
      }
    }
    return opcoesObj;
  }

  opcoesParaStr(opcoesObj: AtributoOpcaoDto[]): string {
    let opcoesStr: string[] = [];
    for (const opcao of opcoesObj) {
      opcoesStr.push(opcao.nome);
    }
    return JSON.stringify(opcoesStr);
  }


}
