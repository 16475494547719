import { Component, OnInit } from '@angular/core';
import { MovimentacaoHistoricoService } from 'src/app/shared/service/movimentacao-historico.service';
import { UtilService } from 'src/app/shared/service/util.service';

@Component({
  selector: 'relatorio-totalizador-entregador',
  templateUrl: './relatorio-totalizador-entregador.component.html',
  styleUrls: ['./relatorio-totalizador-entregador.component.scss']
})
export class RelatorioTotalizadorEntregadorComponent implements OnInit {

  loading = false;
  submitted = false;
  dataInicial;
  dataFinal;

  constructor(
    private service: MovimentacaoHistoricoService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
  }

  imprimir(): void {
    const pattern = /^(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})$/;
    let filtro: string = '';
    let dataInicialValida = true;
    let dataFinalValida = true;
    if (this.dataInicial && this.dataInicial !== '' && this.dataFinal && this.dataFinal !== '') {
      dataInicialValida = this.utilService.isValidDate(this.dataInicial, pattern);
      dataFinalValida = this.utilService.isValidDate(this.dataFinal, pattern);
      filtro += `,datahora>${this.dataInicial},datahora<${this.dataFinal}`;
    } else if (this.dataInicial && this.dataInicial !== '') {
      dataInicialValida = this.utilService.isValidDate(this.dataInicial, pattern);
      filtro += `,datahora>${this.dataInicial}`;
    } else if (this.dataFinal && this.dataFinal !== '') {
      dataFinalValida = this.utilService.isValidDate(this.dataFinal, pattern);
      filtro += `,datahora<${this.dataFinal}`;
    }

    if (dataInicialValida === false || dataFinalValida === false) {
      this.utilService.mensagem('Verifique se as datas estão corretas!', UtilService.MENSAGEM_ERRO);
    }

    this.service
      .relatorioTotalizadorMovimentacao(filtro)
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/pdf',
        });
        const filename = 'relatorio-totalizador-movimentacao.pdf';
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveBlob(blob, filename);
        } else {
          var elem = window.document.createElement('a');
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      });
  }
}