<div class="logo">
    <div *ngIf="imagemPreview">
        <img [src]="imagemPreview">
    </div>
    <div *ngIf="!imagemPreview">
        <img src="../../assets/logo-collie.png">
    </div>
</div>
<mat-nav-list>
    <a mat-list-item routerLink="/visao-geral"><i class="bi bi-globe-americas mr-3"></i>Painel</a>
    <!-- <a mat-list-item routerLink="/ordem-servico">Ordens de Serviço</a> -->
    <a mat-list-item routerLink="/produto"><i class="bi bi-boxes mr-3"></i>Produtos</a>
    <a mat-list-item routerLink="/movimentacao"><i class="bi bi-arrow-left-right mr-3"></i>Movimentação</a>
    <a mat-list-item routerLink="/cliente"><i class="bi bi-person mr-3"></i>Clientes</a>
    <a mat-list-item routerLink="/local"><i class="bi bi-buildings mr-3"></i>Locais</a>
    <a mat-list-item routerLink="/alerta"><i class="bi bi-megaphone mr-3"></i>Alertas</a>
    <a mat-list-item routerLink="/historico-solicitacao"><i class="bi bi-inboxes mr-3"></i>Solicitações</a>
    <a *ngIf="role === 'administrador'" mat-list-item routerLink="/parceria"><i class="bi bi-house-heart mr-3"></i>
        Parcerias</a>
    <a mat-list-item routerLink="/etiqueta"><i class="bi bi-tags mr-3"></i> Etiquetas</a>
    <a mat-list-item routerLink="/produto-tipo"><i class="bi bi-box-seam mr-3"></i>Tipos de Produto</a>
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="white-arrow">
                <mat-panel-title>
                    <i class="bi bi-layout-text-window mr-3"></i>Relatórios
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
                <a mat-list-item routerLink="/relatorio-totalizador-produto">Totalizador de
                    Produtos</a>
                <a mat-list-item routerLink="/relatorio-codigo-produtos">Código dos
                    Produtos</a>
                <a mat-list-item routerLink="/relatorio-totalizador-entregador">Totalizador de Movimentações</a>
            </mat-nav-list>
        </mat-expansion-panel>
    </mat-accordion>
    <a *ngIf="role === 'administrador'" mat-list-item routerLink="/usuario"><i
            class="bi bi-people-fill mr-3"></i>Usuários</a>
    <a *ngIf="role === 'administrador'" mat-list-item routerLink="/configuracao-empresa"><i
            class="bi bi-building-fill-gear mr-3"></i>Empresa</a>

</mat-nav-list>